import React from 'react';
import Autosuggest from 'react-autosuggest';

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion => suggestion.text;

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => {
  var ret = null;
  if (suggestion.description) {
    ret = (
      <div>
        {suggestion.text} <span style={{ fontSize: "75%" }}>{suggestion.description}</span>
      </div>
    );
  } else {
    ret = (
      <div>
        {suggestion.text}
      </div>
    );
  }

  return ret;
}

const renderSectionTitle = section => {
  return <strong>{section.title}</strong>;
};

const getSectionSuggestions = section => {
  return section.suggestions;
};

export default class TireSearchBox extends React.Component {
  constructor(props) {
    super(props);
    const urlParams = new URLSearchParams(window.location.search);
    const eanVal = urlParams.get('ean');

    if (eanVal) {
      props.updateSearchBox({ sSearch: eanVal });
      this.props.refreshNow();
      //  .then(() => {
      //  this.props.refreshNow(e);
      //});
    }

    this.state = {
      value: eanVal,
      suggestions: []
    };
  }

  onChange = (event, { newValue }) => {
    this.props.updateSearchBox({ sSearch: newValue });
    this.setState({
      value: newValue
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    var sections = [];
    if (inputLength) {
      const { searchbox_suggestions_database, chips, fulltiresizes } = this.props.searchBox;

      //HACK
      const brand = chips.find(c => c.key == "brand");
      //HACK
      //HACK
      var filtersSoFar = ["width", "ratio", "diameter"].reduce((a, v) => {
        var chip = chips.find(c => c.key == v) || {};
        var chipValue = chip.value || chip.text;
        return !chipValue ?
          { ...a}
          : {
          ...a,
          [v]: chipValue,
        };
      }, {});
      var smaller = Object.entries(filtersSoFar).length ?
        fulltiresizes
        .filter(s =>
          Object.entries(filtersSoFar).every(([k, v]) => s[k] == v)
        )
        : [];
      //HACK

      Object
        .entries(searchbox_suggestions_database)
        .forEach(([key, valueObject]) => {
          //do not show section if user didn't select yet all required prerequisites
          var addIf = (valueObject.onlyWhen || []).every(r => chips.some(c => c.key == r));
          //do not show section if already selected and is not a multi select field
          addIf = addIf && (!chips.some(c => c.key == key) || !chips.some(c => c.key == key && !c.multi));
          if (addIf)
          {
            sections.push({
              title: key,
              sortIndex: valueObject.sortIndex,
              suggestions: valueObject.suggestions.filter(v =>
                v.text.toLowerCase().startsWith(inputValue)
                //HACK
                && (!brand || !v.brandid || brand.value == v.brandid)
                && !(valueObject.onlyWhen && !smaller.some(s => s[key] == v.value))
                //HACK
              ).map(v => { return { ...v, key: key } })
            });
          }
        });
      sections = sections
        .filter(section =>
          section.suggestions.length > 0
        ).sort((a, b) =>
          a.sortIndex < b.sortIndex ? -1 : a.sortIndex > b.sortIndex ? 1 : 0
        );
    }

    this.setState({
      suggestions: sections
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  onSuggestionSelected = (e, { suggestion, method }) => {
    const selected = suggestion;

    var options = { multiselect: false, required: false };
    const sbdb = this.props.searchBox.searchbox_suggestions_database;
    Object
      .entries(sbdb)
      .forEach(([key, valueObject]) => {
        if (key == selected.key) {
          options = { multiselect: valueObject.multiselect, required: valueObject.required, sortIndex: valueObject.sortIndex };
        }
      });

    const { chips } = this.props.searchBox;
    var _chips = chips;

    if (!options.multiselect) {
      _chips = _chips.filter(c => c.key != selected.key);
    } else {
      _chips = _chips.filter(c => c.value != selected.value);
    }
    _chips.push({ text: selected.text, value: selected.value, key: selected.key, sortIndex: options.sortIndex, multi: options.multiselect });

    this.props.updateChipsSearchBox(_chips);
    this.props.updateSearchBox({ sSearch: "" });
    this.setState({ value: '' });

    if (method === 'enter') {
      e.preventDefault();
    }
  }

  backspace = (e) => {
    if (!this.state.value || e.type == "click") {
      const { chips } = this.props.searchBox;
      var _chips = chips;
      _chips.sort((a, b) =>
        a.sortIndex < b.sortIndex ? -1 : a.sortIndex > b.sortIndex ? 1 : 0
      )
        .splice(-1, 1);
      this.props.updateChipsSearchBox(_chips);
      //for some reason when the input field has focus no redraw is triggered even if new props with updated chips are dispatched
      //the line below force a redraw
      this.props.updateSearchBox({ sSearch: "" });

      if (e.type == "click") {
        this.setState({ value: this.state.value });
      } else {
        this.setState({ value: '' });
      }
    }
  }

  handleKeyDown = (e) => {
    switch (e.key) {
      case "Backspace":
        this.backspace(e);
        break;
      case "Enter":
        var isOpen = document.querySelector(".react-autosuggest__suggestions-container.react-autosuggest__suggestions-container--open");
        if (!isOpen) {
          this.props.refreshNow(e);
        }
        break;
      default:
        break;
    }
  }

  render() {
    const { value, suggestions } = this.state;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: 'Type something',
      value: this.props.searchBox.sSearch || '',
      onChange: this.onChange,
      onKeyDown: this.handleKeyDown
    };

    // Finally, render it!
    return (
      <div>
        <div className="row">
          <div className="col-10">
            <Autosuggest
              multiSection={true}
              renderSectionTitle={renderSectionTitle}
              getSectionSuggestions={getSectionSuggestions}

              suggestions={suggestions}
              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
              onSuggestionSelected={this.onSuggestionSelected}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
              inputProps={inputProps}
            />
          </div>
          <div className="col-2">
            <button className="btn btn-primary btn-lg" onClick={(e) => this.props.refreshNow(e)}>Search</button>
          </div>
        </div>
        <TireSearchBoxChips
          chips={this.props.searchBox.chips}
          updateChipsSearchBox={this.props.updateChipsSearchBox}
          backspace={this.backspace}
          searchbox_suggestions_database={this.props.searchBox.searchbox_suggestions_database}
        />
      </div>
    );
  }
}

class TireSearchBoxChips extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    const { chips, searchbox_suggestions_database } = this.props;

    var missingChips = [];
    var required = [];
    Object
      .entries(searchbox_suggestions_database)
      .forEach(([key, valueObject]) => {
        if (valueObject.requiresAll && chips.some(c => c.key == key)) {
          required = required.concat(valueObject.requiresAll);
        }
      });
    required = [...new Set([].concat(...required))];
    //required = required.filter(r => !chips.some(c => c.key == r));

    Object
      .entries(searchbox_suggestions_database)
      .forEach(([key, valueObject]) => {
        if (required.some(r => r == key) && !chips.some(c => c.key == key)) {
          missingChips.push(
            {
              key: key,
              text: key+"???",
              sortIndex:valueObject.sortIndex
            }
          );
        }
      });

    //var badge_class = "";
    //if (displayChips.some(c => c.text.includes("???"))) {

    //}

    var deleteButton = null;
    var backSpaceButton = null;
    var _chips = [];
    var displayChips = chips.concat(missingChips);
    if (displayChips.length) {
      displayChips
        .sort((a, b) => 
          a.sortIndex < b.sortIndex ? -1 : a.sortIndex > b.sortIndex ? 1 : 0
        )
        .forEach((v, i, a) => {

          _chips.push(
            <a
              key={v.key + i}
              href="#"
              onClick={(e) => { this.props.updateChipsSearchBox(chips.filter(c => !(c.key == v.key && (v.value || v.text) == (c.value || c.text)))) }}
              className={v.text.includes("???") ? "badge badge-danger" : "badge badge-success"}
              style={{ marginRight: 2 }}
            >{v.text}</a>
          );
        });
      deleteButton = <a href="#" className="deleteButton" onClick={(e) => this.props.updateChipsSearchBox([])}><i className="fas fa-trash"></i></a>
      backSpaceButton = <a href="#" className="backSpaceButton" onClick={(e) => this.props.backspace(e)}><i className="fas fa-backspace"></i></a>
    }

    return (
      <div className="TireSearchBoxChips">
        <div className="row">
          <div className="col-8">
            {_chips}
          </div>
          <div className="col-2">
            <div className="text-right">
              {deleteButton}
              {backSpaceButton}
            </div>
          </div>
          <div className="col-2">
          </div>
        </div>
      </div>
    );
  }
}
