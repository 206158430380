import HttpRequest from '../../utils/HttpRequest';

import { isProcessing } from "../actions/actProcessing";

import { ACTN_REFRESH, ACTN_REFRESH_SINGLE_AFTER_UPDATE, ACTN_UPDATE_SEARCHBOX, ACTN_UPDATE_BRAND } from "../config/action-types.js";

function _updateChipsSearchBox(chips) {
  return dispatch => {
    dispatch({
      type: "ACTN_UPDATE_CHIPS",
      chips: chips
    });
  }
}

export const updateChipsSearchBox = (data) => (dispatch, getState) => {
  return dispatch(_updateChipsSearchBox(data))
}

const _updateSearchBox = (data) => {
  return dispatch => {
    return dispatch({
      type: ACTN_UPDATE_SEARCHBOX,
      data: data
    });
  }
}

const _updateBrand = (data) => {
  return dispatch => {
    return dispatch({
      type: ACTN_UPDATE_BRAND,
      data: data
    });
  }
}

export const updateSearchBox = (data) => (dispatch, getState) => {
  return dispatch(_updateSearchBox(data))
}

export const updateBrand = (data) => (dispatch, getState) => {
  return dispatch(_updateBrand(data, getState))
}

export const updateResults = (data) => (dispatch, getState) => {
  return dispatch({
    type: ACTN_REFRESH,
    data: data
  })
}

//const _updateResults = (data) => {
//  return dispatch => {
//    dispatch({
//      type: ACTN_REFRESH,
//      data: data
//    });
//  }
//}

//export const updateResults = (data) => (dispatch, getState) => {
//  return dispatch(_updateResults(data))
//}


const _doSomething = (url, payload, getState) => {
  return dispatch => {

    var state = getState();

    var filters = { ...state.tires.chips};
    //var filters = state.tires.chips.reduce((a, cv) => {
    //  return {
    //    ...a,
    //    //if a[cv.key] already exists makes it an array, for multivalue filters like distributors
    //    [cv.key]: cv.multi ?
    //      a[cv.key] ?
    //        a[cv.key].concat(cv.value || cv.text)
    //        : [cv.value || cv.text]
    //      : cv.value || cv.text
    //  }
    //}, {});

    filters =
    {
      ...filters,
      ...state.tires.searchBox
    };

    var headers = [];
    if (state.pusher && state.pusher.socket_id) {
      headers.push({ header: "pusher-socket_id", value: state.pusher.socket_id });
    }

    payload = {
      ...payload,
      filters: { ...filters }
    };

    //var url = "/bo/tires/do-something";

    dispatch(isProcessing({
      key: url,
      isProcessing: true
    }));

    var xhr = new HttpRequest("POST", url, "application/json", headers);
    return xhr.send(payload).then((response) => {
      var json = response.json;
      if (json.message || json.exception) {
        alert(json.message || json.exception);
      } else {
      }
      dispatch({
        type: ACTN_REFRESH,
        data: json
      });
      //if (!payload.action) {
      //} else {
      //  dispatch({
      //    type: ACTN_REFRESH_SINGLE_AFTER_UPDATE,
      //    data: json
      //  });
      //}

      dispatch(isProcessing({
        key: url,
        isProcessing: false
      }));

      return json;
    }).catch((e) => {
      dispatch(isProcessing({
        key: url,
        isProcessing: false
      }));
    });
  }
}

export const doSomething = (url, payload) => (dispatch, getState) => {
  return dispatch(_doSomething(url, payload, getState))
}

//function _refreshSearchDatabaseIfStale() {
//  return dispatch => {
//    return initialSearchDatabaseFromServer(dispatch).then((data) => {
//      dispatch({
//        type: ACTN_REFRESH_DATABASE,
//        database: data
//      })
//    });
//  }
//}

//export const refreshSearchDatabaseIfStale = (data) => (dispatch, getState) => {
//  var state = getState();
//  var database = state.tires.database;
//  var nowis = Date.now();
//  var staleAfter = 1000 * 60 * 60 * 24;
//  if (!(database && database.nowis
//    && ((nowis - database.nowis) < staleAfter)
//    && database.nowis > staleBefore)) {
//    return dispatch(_refreshSearchDatabaseIfStale(data))
//  }
//}

//var initialSearchDatabaseFromServer = (dispatch) => {
//  var url = (location.href.includes("localhost") || 1==1 ?
//    "/bo/search/searchform" :
//    ("https://d2wwsiiaf1due0.cloudfront.net") + "/bo/search/searchform");

//  dispatch(isProcessing({
//    key: url,
//    isProcessing: true
//  }));

//  var xhr = new HttpRequest("GET", url, "application/json");

//  return xhr.send().then((response) => {
//    dispatch(isProcessing({
//      key: url,
//      isProcessing: false
//    }));
//    return {
//      ...response.json,
//      nowis: Date.now()
//    }
//  }).catch((e) => {
//    dispatch(isProcessing({
//      key: url,
//      isProcessing: false
//    }));
//  });
//}

//const staleBefore = 1566037717665;
//export const isStale = (state) => {
//  return !(state && state.tires && state.tires && state.tires.database && state.tires.database.nowis > staleBefore);
//}

//export function actiongetAllTires(key, state) {
//  let command = new GetAllTiresCommand(key).builder({}).withCacheLimit(30).withVersion("2.0");

//  return dispatch => {
//    return command.execute().then((data) => {
//      state.distributors = data.distributors;
//      dispatch({
//        type: ACTN_ALL_TIRES,
//        payload: data,
//        state: state
//      })
//    })
//  }
//}

//class GetAllTiresCommand extends Command {
//  constructor(key) {
//    super(key);
//    this.url = (location.href.includes("localhost") ?
//      "/bo/search/searchform" :
//      "https://d2wwsiiaf1due0.cloudfront.net") + "/bo/search/searchform";
//  }

//  builder = (body) => {
//    this.build(new HttpRequest(
//      "GET",
//      this.url,
//      "application/json"
//    ), body);
//    return this;
//  }
//}
