import React from 'react';
import ReactDOM from 'react-dom';
import NavBar from './shared/navbar.jsx';
import FooterTools from './shared/footer.jsx'

import AllCaiDaddy from './bo/all-tires/bo-all-tires.jsx'
import TiresPriceHistory from './bo/history/prices/tires.jsx'
import EbayItem from './bo/ebay/item.jsx'
import ProductDryRunImport from "./bo/all-tires/product-dry-run-import.jsx"
import DrawerDaddy from './bo/drawer/drawer-daddy.jsx';

import { Provider } from 'react-redux';
import configureStore from '../gcomponents/store/config/config'

//window.GLOBAL_ENV = "dev";
const store = configureStore();


class AllCaiDaddyContainer extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <AllCaiDaddy none="Moon" />
      </Provider>
    )
  }
}

if (document.getElementById('bo-all-tires')) {
  ReactDOM.render(
    <AllCaiDaddyContainer />,
    document.getElementById('bo-all-tires')
  );
}

var el = document.getElementById("bo-tires-price-history");
if (el) {
  ReactDOM.render(
    <TiresPriceHistory />,
    el
  );
}

el = document.getElementById("bo-ebay-item");
if (el) {
  ReactDOM.render(
    <Provider store={store}>
      <EbayItem />
    </Provider>
  ,
    el
  );
}

el = document.getElementById("product-dry-run-import");
if (el) {
  ReactDOM.render(
    <Provider store={store}>
      <ProductDryRunImport />
    </Provider>
    ,
    el
  );
}

el = document.getElementById("navbar");
if (el) {
  var IsAuthenticated = el.dataset.isauthenticated == "True";
  var name = el.dataset.name;
  ReactDOM.render(
    <Provider store={store}>
      <NavBar IsAuthenticated={IsAuthenticated} Name={name} />
    </Provider>,
    el
  );
}

el = document.getElementById("footer-tools");
if (el) {
  var IsAuthenticated = el.dataset.isauthenticated == "True";
  var name = el.dataset.name;
  ReactDOM.render(
    <Provider store={store}>
      <FooterTools IsAuthenticated={IsAuthenticated} Name={name} />
    </Provider>,
    el
  );
}

el = document.getElementById("bo-drawer");
if (el) {
  ReactDOM.render(
    <Provider store={store}>
      <DrawerDaddy />
    </Provider>,
    el);
}
