// A '.tsx' file enables JSX support in the TypeScript compiler, 
// for more information see the following page on the TypeScript wiki:
// https://github.com/Microsoft/TypeScript/wiki/JSX

import React from 'react';

import memoize from "memoize-one";

import { AllCaiFilter } from './bo-allcai-filter.jsx';
import { AllCai } from './bo-allcai-list.jsx';

import { BrandCreate } from './../bo-brand-create.jsx';

import { TireMapper } from './../bo-tire-editor-mapping.js';

//state management
import { connect } from 'react-redux';
import { updateChipsSearchBox, updateSearchBox, updateResults, doSomething } from '../../../gcomponents/store/actions/actAllTires';

import ProgressLoading from '../../../gcomponents/utils/ProgressLoading'
import { last } from 'lodash';

class AllCaiDaddy extends React.Component {
  constructor(props) {
    super(props);

    var sSearch = '';

    this.isProgessingTimeout = null;
    this.progress = 10;

    //we need to replace this with something else when we migrate synonym management
    var urlParams = new URLSearchParams(window.location.search);
    const synonym = urlParams.get('synonym');
    const pattern = urlParams.get('pattern');
    const brandid = urlParams.get('brandid');
    
    if (brandid && synonym && pattern) {
      sSearch = { brandid: brandid, synonym: synonym, pattern: pattern };
    }
    //we need to replace this with something else when we migrate synonym management

    //var brands = window.global ? window.global.brands : [];
    //var patterns = window.global ? window.global.patterns : [];
    //var sizes = window.global ? window.global.sizes : [];

    //console.log(db);
    //console.log("searchbox_suggestions_database", searchbox_suggestions_database);

    var dashboard = {};
    if (window.globalData !== undefined) {
      dashboard = window.globalData.dashboard;
    }

    this.state = {
      tire_being_edited: {},

      tags: [],

      //brands: brands,
      //patterns: patterns,
      //sizes: sizes,

      lastRequest: null,
      dashboard: dashboard,

      modal: {
        id: "",
        modalData: {

        }
      },

      //distributors: [],
      //results: []
      //,

      //ssfd: null

      domain: "",
      country: ""
    };
  }

  //componentDidUpdate(prevProps) {
  //  var doRefresh = (!prevProps.searchBox || (this.props.searchBox && this.props.searchBox.sSearch && this.props.searchBox.sSearch != prevProps.searchBox.sSearch));
  //  if (doRefresh) {
  //    this.refreshNow();
  //  }
  //}

  crunchSearchBoxDatabase = memoize((db) => {
    var searchbox_suggestions_database = {}

    if (db && Object.entries(db).length)
      searchbox_suggestions_database = {
        distributors: {
          suggestions: db.distributors.map(d => { return { text: d.name, value: d.id } }),
          sortIndex: 9,
          multiselect: true
        },
        brands: {
          suggestions: db.brandoptions.map(b => { return { text: b.description, value: b.id } }),
          sortIndex: 7,
          multiselect: true
        },
        pattern: {
          suggestions: db.patternoptions.map(b => { return { text: b.description, value: b.value, brandid: b.brandid } }),
          sortIndex: 8,
          multiselect: false
        },
        width: {
          suggestions: db.widthoptions.map(w => { return { text: w.width } }),
          sortIndex: 0,
          multiselect: false,
          requiresAll: ["ratio","diameter"]
        },
        ratio: {
          suggestions: db.ratiooptions.map(r => { return { text: r.ratio } }),
          sortIndex: 1,
          multiselect: false,
          requiresAll: ["width", "diameter"]
        },
        diameter: {
          suggestions: db.diameteroptions.map(d => { return { text: d.diameter } }),
          sortIndex: 2,
          multiselect: false,
          requiresAll: ["ratio", "width"]
        },
        load: {
          suggestions: db.loadoptions.map(d => { return { text: d.description, value: d.symbol } }),
          sortIndex: 3,
          multiselect: false,
          onlyWhen: ["ratio", "width", "diameter"]
        },
        speed: {
          suggestions: db.speedoptions.map(d => { return { text: d.description, value: d.symbol } }),
          sortIndex: 4,
          multiselect: false,
          onlyWhen: ["ratio", "width", "diameter"]
        },
        season: {
          suggestions: [{ text: "summer", value: "EST" }, { text: "winter", value: "INV" }, { text: "all-season", value: "ALL" }],
          sortIndex: 5,
          multiselect: false
        },
        runflat: {
          suggestions: [{ text: "runflat" }],
          sortIndex: 6,
          multiselect: false,
          onlyWhen: ["ratio", "width", "diameter"]
        }
      };
    return searchbox_suggestions_database;
  });

  pushToCart = (e, r) => {
   
    var payload = {
      distributorid: r.DistributorId, brandid: r.BrandID, cai: r.Cai, uniquetireid: r.UniqueTyreId, quantity: 4, buybecause: "",
      broadcast: {
        event: "cart-refresh", data: { cartid: this.props.minicart.cartid }
      }
    };

    this.props.doSomething("/bo/tires/cart/push", payload);
  }

  refreshNow = () => {

    this.props.doSomething("/bo/tires/search").then(d => {
      if (d) {
        var ns = {};
        if (d.dashboard) {
          ns = { ...ns, dashboard: d.dashboard }
        }
        if (d.responseTime) {
          ns = {
            ...ns,
            lastRequest: {
              responseTime: d.responseTime
            }
          }
        }
        this.setState(ns);

        //console.log("/bo/tires/search", ns)

      }

      return d;
    });
    //this.props.doSomething("/bo/search/allcaiv2");

  }

  // visto che c'è e non è utilizzata riutilizziamo questa funzione per la gestione del domain e country

  newSomething = (e, what) => {
    switch (what) {
      case "brand":
        this.setState({ showBrandCreateModal: true });
        break;
      case "domain":
        this.setState({ domain: e.value });
        break;
      case "country":
        this.setState({ country: e.value });
        break;
    }
  }

  doSomething = (e, r, action, extra) => {
    switch (action) {
      case "do-prices-simulation":
        var payload = { domain: r.domain, action: action, brandid: r.BrandID, cai: r.Cai, ean: r.EAN, uniquetyreid: r.UniqueTyreId, distributorid: r.DistributorId, ui: r.ui };
        this.props.doSomething("/bo/tires/do-something", payload);
        break;

      case "toggle-economics":
        var showEconomics = r.ui && r.ui.economics ? false : true;
        if (showEconomics) {
          r.savedCopy = Object.assign({}, r);
        } else {
          if (r.savedCopy) {
            r = Object.assign({}, r.savedCopy, { savedCopy: null });
          }
        }
        r.ui = { economics: showEconomics };
        this.props.results.forEach((v, i, a) => {
          if (v.Cai != r.Cai || (v.BrandID != r.BrandID && r.old_brandid != v.BrandID && r.new_brandid != r.BrandID) || v.UniqueTyreId != r.UniqueTyreId || v.DistributorId != r.DistributorId) {
          } else {
            a[i] = Object.assign({}, v, r);
          }
        });
        this.setState({
          results: this.props.results
        });
        break;

      case "toggle-details":
        var showDetails = r.ui && r.ui.details ? false : true;
        if (showDetails) {
          this.setState({ tire_being_edited: { ...r } });

          //r.savedCopy = Object.assign({}, r);
        } else {
          this.setState({ tire_being_edited: { } });

          //if (r.savedCopy) {
          //  r = Object.assign({}, r.savedCopy, { savedCopy: null });
          //}
        }
        r.ui = { details: showDetails };
        this.props.results.forEach((v, i, a) => {
          if (v.Cai != r.Cai || (v.BrandID != r.BrandID && r.old_brandid != v.BrandID && r.new_brandid != r.BrandID) || v.UniqueTyreId != r.UniqueTyreId || v.DistributorId != r.DistributorId) {
            a[i] = { ...v, ui: { details: false } };
          } else {
            a[i] = Object.assign({}, v, r);
          }
        });
        this.props.updateResults({
          results: this.props.results
        });
        break;

      case "update-tire-being-edited":
        this.setState({ tire_being_edited: { ...r } });
        break;

      //case "update-details":
      //  this.props.results.forEach((v,i,a) => {
      //    if (v.Cai != r.Cai || (v.BrandID != r.BrandID && r.old_brandid != v.BrandID && r.new_brandid != r.BrandID) || v.UniqueTyreId != r.UniqueTyreId || v.DistributorId != r.DistributorId) {
      //    } else {
      //      a[i] = Object.assign({}, v, r);
      //    }
      //  });
      //  break;

      case "do-save":
      case "do-prices":
      case "zero-quantity":
      case "toggle-skip":
        var payload = { action: action, brandid: r.BrandID, cai: r.Cai, ean: r.EAN, uniquetyreid: r.UniqueTyreId, distributorid: r.DistributorId };
        switch (action) {
          case "do-save":
            payload = Object.assign({}, payload, { tire: TireMapper.mapSomething(r, "line") }, extra);
            break;
          case "do-prices":
            payload = Object.assign({}, payload, { prices: r.prices });
            break;
          case "zero-quantity":
          case "toggle-skip":
            break;
        }

        this.props.doSomething("/bo/tires/do-something", payload);

        break;
      case "get-elect-by-ean":
        let self = this;
        this.props.doSomething("/bo/tires/search-elect" , r).then(data => {
          if (JSON.stringify(data.elect) !== "{}") {
            let edited = this.state.tire_being_edited;
            let e = data.elect;
             /* substitute this.state.tire_being_edited with the found elect updating only data went from ean_master_data */
            self.doSomething(
              data,
              {
                ...edited,

                newEan: e.elect ? 2 : 1,

                CodStagione: e.CodStagione, BrandID: e.BrandID, brand: e.brand, 
                Larghezza: e.Larghezza, Serie: e.Serie, Diametro: e.Diametro,
                Carico: e.Carico, CodVelocita: e.CodVelocita,
                Disegno: e.Disegno, DatiAggiuntiviDescrizione: e.DatiAggiuntiviDescrizione,
                IsRunFlat: e.IsRunFlat, EULGAS: e.EULGAS, EULRAI: e.EULRAI, EULNC: e.EULNC, EULDB: e.EULDB,


                /*Cai: edited.Cai, UniqueTyreId: edited.UniqueTyreId, DistributorId: edited.DistributorId,
                dot: edited.dot, isdemo: edited.isdemo*/
              },
              "update-tire-being-edited"
            );
          }
        });
        break;
    }
  }

  dismiss = (e, modal) => {
    if (modal == this.state.modal) {
      this.setState({
        modal: {
          id: "",
          modalData: {

          }
        }
      });
    } else {
      switch (modal) {
        case "brand":
          this.setState({ showBrandCreateModal: false });
          break;
      }
    }
  }
  
  render() {
    var { brands, patterns, sizes, tags, tire_being_edited, dashboard } = this.state;
    var { chips, database, results } = this.props;

    var ret = null;

    ret = (
      <div>
        <ProgressLoading processing={this.props.isProcessing} />

        <AllCaiFilter
          updateChipsSearchBox={this.props.updateChipsSearchBox}
          updateSearchBox={this.props.updateSearchBox}
          isProcessing={this.props.isProcessing}
          handleSearch={this.handleSearch}
          searchBox={{
            ...this.props.searchBox,
            chips: chips || [],
            searchbox_suggestions_database: this.crunchSearchBoxDatabase(database),
            fulltiresizes: database.fulltiresizes
          }}
          refreshNow={this.refreshNow}
          newSomething={this.newSomething}

          domain={this.state.domain}
          country={this.state.country}

          tags={tags}
          brands={brands}
          patterns={patterns}
          dashboard={dashboard}
        />
        <AllCai
          isProcessing={this.props.isProcessing}
          results={results}
          tire_being_edited={tire_being_edited}
          pushToCart={this.pushToCart}
          doSomething={this.doSomething}
          ssfd={database}

          domain={this.state.domain}
          country={this.state.country}
        />
        {this.state.showBrandCreateModal ?
          <BrandCreate
            isOpen={true}
            close={this.dismiss} />
          : null}
        <ResponseTime
          lastRequest={this.state.lastRequest}
        />
      </div>
    );

    return ret;
  }
}

function ResponseTime({lastRequest}) {
  var ret = null;

  if (lastRequest && lastRequest.responseTime) {
    ret = <div>
      <span className="badge badge-light">Last response time: {lastRequest.responseTime.toFixed(2)}ms</span>
    </div>
  }

  return ret;
}

const mapStateToProps = state => {
  return {
    minicart: state.minicart,
    database: state.tires.database,
    searchBox: state.tires.searchBox,
    chips: state.tires.chips,
    results: state.tires.results,
    elect: state.tires.elect,
    isProcessing: state.isProcessing.mostRecentStatus
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateChipsSearchBox: (chips) => dispatch(updateChipsSearchBox(chips)),
    updateSearchBox: (data) => dispatch(updateSearchBox(data)),
    updateResults: (data) => dispatch(updateResults(data)),
    doSomething: (url, payload) => dispatch(doSomething(url, payload))
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(AllCaiDaddy);
