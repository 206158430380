import React from 'react';
import ReactDOM from 'react-dom';

import HttpRequest from 'helper-xhr';

import Modal from 'react-modal';

import { countries } from '../data/countries.js';

const customStyles = {
  content: {
    border: '10px solid rgba(0,0,0,.7)',
    borderRadius: "10px",
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50.1%, -50.1%)'
  }
};

class BrandCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      brand: {
        brand: "",
        synonyms: "",
        tier: "D",
        dotwk: 30,
        country: "IT",
        disabled: false
      },
      isProcessing: false,
      isEditing: false,
      isSaved: false
    };

    this.afterOpen = this.afterOpen.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.create = this.create.bind(this);
  }

  afterOpen() {
    document.getElementsByTagName("body")[0].style.overflowY = "hidden";
  }

  closeModal(e) {
    this.props.close(e, "brand");
    document.getElementsByTagName("body")[0].style.overflowY = "auto";
  }

  create(e) {
    this.setState({
      isSaved: false,
      isProcessing: true,
      isEditing: false
    });
    //SpinnerUtility.spin(document.body);
    var payload = this.state.brand;
    var xhr = new HttpRequest("POST", "/ajax/backoffice/tires/brand/new", "application/json");
    xhr.send(payload).then((response) => {
      var json = response.json;
      if (json.message || json.exception || json.errors) {
        if (json.errors) {
          this.setState({ errors: json.errors });
        } else {
          alert(json.message || json.exception);
        }
      } else {
        window.localStorage.removeItem("bo-searchformdata");
        window.localStorage.removeItem("searchformdata");
        window.localStorage.removeItem("searchformdata-all-combinations-v2");
        this.setState({
          errors: [],
          isSaved: true,
          isProcessing: false
        });
      }
      //SpinnerUtility.stop();
    }).catch((e) => {
      this.setState({
        isProcessing: false
      });
      //SpinnerUtility.stop();
    });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      brand: Object.assign({}, this.state.brand, { [name]: value }),
      isEditing: true,
      errors: []
    });
  }

  render() {
    var ret = null;

    var brand = this.state.brand;

    var csel = countries.map((v, i) => {
      return <option key={v.code + "-" + i} value={v.code}>{v.name}</option>
    });

    var hasError = () => { return "" };
    var errors = [];
    if (!this.state.isEditing && this.state.errors && this.state.errors[0]) {
      hasError = (n) => this.state.errors.some((v) => v.reference == n) ? " has-error " : "";
      errors = this.state.errors.map((v, i) => {
        return <span key={"error-" + i} className="help-block" style={{ fontWeight: 700 }}><i className="fa fa-exclamation-triangle"></i> {v.message}</span>;
      });
    }

    var buttons = <div className="row">
      <div className="col-md-12" style={{ textAlign: "right" }}>
        <button type="submit" className="btn btn-default" style={{ marginRight: "10px" }}
          onClick={() => this.closeModal()}
        >Cancel</button>
        <button type="submit" className="btn btn-danger"
          onClick={(e) => this.create(e)}
        >Save</button>
        <div className="form-group has-error">
          {errors}
        </div>
      </div>
    </div>;
    if (this.state.isSaved) {
      var buttons = <div className="row">
        <div className="col-md-12" style={{ textAlign: "right" }}>
          <button type="submit" className="btn btn-danger disabled" disabled={true}
          ><i className="fa fa-check"></i> Saved</button>
          <div className="form-group has-error">
            {errors}
          </div>
        </div>
      </div>;
    }

    var content = <div>
      <div className="row">
        <div className="col-md-12">
          <div className={"form-group " + hasError("brand")} style={{ display: 'block' }}>
            <label>Brand</label>
            <input type="text" className="form-control"
              name="brand"
              value={brand.brand}
              onChange={this.handleChange}
            />
            <p className="help-block">E.g. "Goodyear".</p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className={"form-group " + hasError("synonyms")} style={{ display: 'block' }}>
            <label>Synonyms</label>
            <input type="text" className="form-control"
              name="synonyms"
              value={brand.synonyms}
              onChange={this.handleChange}
            />
            <p className="help-block">E.g. "Good-year,good year, goodye" (comma separated).</p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <div className={"form-group " + hasError("tier")} style={{ display: 'block' }}>
            <label>Tier</label>
            <select className="form-control"
              name="tier"
              value={brand.tier}
              onChange={this.handleChange}
            >
              <option>A</option>
              <option>B</option>
              <option>C</option>
              <option>D</option>
            </select>
          </div>
        </div>
        <div className="col-md-4">
          <div className={"form-group " + hasError("dotwk")} style={{ display: 'block' }}>
            <label>DOT Weeks</label>
            <input type="number" className="form-control"
              name="dotwk"
              value={brand.dotwk}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="col-md-4">
          <label>Country</label>
          <select className="form-control"
            name="country"
            value={brand.country}
            onChange={this.handleChange}
          >
            {csel}
          </select>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="checkbox">
            <label>
              <input type="checkbox"
                name="disabled"
                checked={brand.disabled == true}
                onChange={this.handleChange}
              />
              Disabled
            </label>
          </div>
        </div>
      </div>
      {buttons}
    </div>;

    ret = <Modal
      style={customStyles}
      onAfterOpen={this.afterOpen}
      isOpen={this.props.isOpen}
      onRequestClose={this.closeModal}
      closeOnEscape={true}
      ariaHideApp={false}
    >
      <a href="#" onClick={() => this.closeModal()} style={{ position: "absolute", top: 3, right: 5, color: "rgba(0, 0, 0, 0.5)" }}><i className="fa fa-times fa-2x" aria-hidden="true"></i></a>
      <div>{content}</div>
    </Modal>;

    return ret;
  }
}

export { BrandCreate }