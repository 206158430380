export function formatPrice(value) {
  if (value === null || value === undefined || value === "") {
    return "";
  }

  let number = Number(value); // Converte il valore in numero

  if (isNaN(number)) {
    return value;
  }

  return number.toFixed(2); // Formatta il numero con due decimali
}