import React, { useEffect, useRef, useState } from 'react';

import Autosuggest from 'react-autosuggest';

import Select from 'react-select'

import { formatPrice } from '../../shared/format'

let Countries = require("localized-countries")(require("localized-countries/data/it"));

function ChannelSelector({ pricesExploded, onChannelChange }) {
  var domains = pricesExploded.map(p => p.domain)
  domains = [...new Set(domains)]
  const options = domains.map(d => ({ value: d, label: d }))
  return (
    <Select
      options={options}
      onChange={onChannelChange}
    />
  )
}
function PriceSimulation({ line }) {
  var ret = null;

  if (line.priceSimulation) {
    var names = ["domain", "country", "buyingprice", "bestprice", "bestprice_in_db", "bestprice_without_topprice", "topprice", "fixedprice", "pricetoshow"]
    var mustmatch = ["bestprice", "bestprice_in_db"]
    var head = []
    names.forEach((n, ii) => {
      head.push(
        <th key={"head-"+ii}>
          <div>
            {n.replace(/_/g, ' ')}
          </div>
        </th>
      )
    })

    var lines = [];
    if (line.priceSimulation && line.priceSimulation[0]) {
      line.priceSimulation.forEach((r, i) => {
        console.log("priceSimulation",i,r)
        var cols = []
        //var missingNames = []
        names.forEach((n, ii) => {

          var doesmatch = null;
          var error = null;
          var badge = null;
          if (mustmatch.some(mm => mm = n)) {
            var a = r[mustmatch[0]]
            var b = r[mustmatch[1]]
            doesmatch = a && b && a == b
            if (!doesmatch) {
              error = (<i className="fas fa-exclamation-triangle" style={{ color: "#dc3545", verticalAlign: "middle" }}></i>);
              badge = "badge badge-danger ";
            }
          }

          if (r[n]) {
            cols.push(
              <td key={"row-" + i + "-col-" + ii}>
                <div>
                  <span className={badge}>
                    {formatPrice(r[n])}
                  </span>
                </div>
              </td>
            )
          } else {
            var fixed = line.pricesFixedExploded.find(rr => rr.country == r.country && rr.domain == r.domain)
            if (fixed && fixed[n]) {
              cols.push(
                <td key={"row-" + i + "-col-" + ii}>
                  <div>  
                    {formatPrice(fixed[n])}
                  </div>
                </td>
              )
            } else {
              cols.push(
                <td key={"row-" + i + "-col-" + ii}>
                  <div>
                    
                  </div>
                </td>
              )
            }
          //  missingNames.push(n)
          }
        })
        lines.push(
          <tr key={"row-" + i}>
            {cols}
          </tr>
        );
      })
    } else {
      lines.push(
        <tbody key={"line-empty"}>
          <tr>
            <td colSpan={names.length}>
              <div>
                No prices
              </div>
            </td>
          </tr>
        </tbody>
      );
    }

    ret = (
      <div>
        <table className="table table-striped table-condensed priceSimulation">
          <thead>
            <tr>
              {head}
            </tr>
          </thead>
          <tbody>
            {lines}
          </tbody>
        </table>
      </div>
    )
  }

  return ret;
}

class SingleCaiPrices extends React.Component {
  constructor(props) {
    super(props);

    var r = this.props.line;
    this.state = {};

    var r = props.line;
    this.state = {
      fixedprice: r.fixedprice > 0 ? r.fixedprice : 0,
      pricetoshow: r.pricetoshow > 0 ? r.pricetoshow : 0,
      confirmSave: true,
      error: false,
      selectedChannel: "",
      channelCountries: [],
      selectedCountry: "",
      channelCountryPrice: {
        bestprice: 0,
        buyingprice: 0
      },
      prices: [],
      prices_fixed: [],
      selectedIndex: -1,
      resetFixedPrices: false,
      hasFixed: false,
      editorPriceType: [],
      editorPriceTypeV: "",
      suggestions: [],
      prices: [],
      changed: {}
    };
  }

  componentDidMount() {
    this.refreshPricesState();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps === this.props) return;

    this.refreshPricesState();
    this.setState({ selectedChannel: "" });
  }

  refreshPricesState = () => {
    let props = this.props;
    let state = this.state;
    //let prices = JSON.parse(props.line.prices);
    let prices = props.line.prices;
    //let prices_fixed = JSON.parse(props.line.prices_fixed);
    let prices_fixed = props.line.prices_fixed;
    let prices_fixed_merged = [];

    let statePrices = {};
    state.editorPriceType = prices.map(p => p.channel)
    state.hasFixed = false;

    state.editorPriceType.forEach(channel => {
      let priceList = prices.find(p => p.channel == channel);
      if (priceList.prices) {
        let merged = []
        priceList.prices.forEach(price => {
          var prices_fixed_channel = prices_fixed.find(pf => pf.channel == channel);
          if (prices_fixed_channel) {
            var fixed = prices_fixed_channel.prices && prices_fixed_channel.prices.find(fp => fp.country == price.country);
            if (fixed) {
              merged.push(fixed);
            } else {
              merged.push(price);
            }
          }
        });
        prices_fixed_merged.push({ channel: channel, prices: merged });
      }
    });

    for (let p in prices_fixed_merged) {
      let fixed = prices[p];
      let price;

      if (typeof fixed.prices === "undefined") {
        price = prices_fixed.filter((price) => price.channel === fixed.channel);
      } else {
        price = prices.filter((price) => price.channel === fixed.channel);
        if (price.length > 0) state.hasFixed = true;
      }

      if (price.length > 0) { price = price[0]; } else { price = null; }
      if (price !== null && typeof price.prices !== "undefined" && price.prices.length > 0) {
        if (typeof statePrices[fixed.channel] === "undefined") statePrices[fixed.channel] = price.prices;
      }
    }

    //state.editorPriceType = prices.map(p => p.channel)
    state.prices = statePrices;

    var r = props.line;
    state.fixedprice = r.fixedprice > 0 ? r.fixedprice : 0;
    state.pricetoshow = r.pricetoshow > 0 ? r.pricetoshow : 0;
    state.prices_ruled = prices;
    state.prices_fixed = prices_fixed;
    this.setState(state);
  }

  mergePrices = (defaultRecord, specialized) => {
    var record = [];
    var defaultRec = JSON.parse(JSON.stringify(defaultRecord));
    for (var s = 0; s < specialized.length; s++) {
      for (var r = 0; r < defaultRec.length; r++) {
        if (defaultRec[r].country === specialized[s].country) {
          defaultRec[r].bestprice = specialized[s].bestprice;
          defaultRec[r].buyingprice = specialized[s].buyingprice;
          break;
        }
      }
    }
    return defaultRec;
  }

  handleInput = (e) => {
    const target = e.target;
    var value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (name == "fixedprice" || name == "fixedprice") {
      value = 1 * value;
    }

    var confirmSave = this.state.confirmSave;
    var error = this.state.error;
    var r = this.props.line;
    if (name == "fixedprice") {
      value = 1 * value;
      if (value > 0) {
        var fixedpriceNoVat = value / 1.22;
        if ((fixedpriceNoVat - r.buyingprice) < 4) {
          error = false;
          confirmSave = true;
        } else {
          error = false;
          confirmSave = true;
        }
      }
    }

    this.setState({ error: error, confirmSave: confirmSave, [name]: value });
  }

  handlePriceInput = (e) => {
    let country = e.target.dataset.country;
    let value = e.target.value;
    let prices = this.state.prices;
    let changed = this.state.changed;
    let state = {};

    for (var i = 0; i < prices[this.state.selectedChannel].length; i++) {
      if (prices[this.state.selectedChannel][i].country === country)
        prices[this.state.selectedChannel][i].bestprice = value;
    }

    if (typeof changed[this.state.selectedChannel] == "undefined") {
      changed[this.state.selectedChannel] = [];
    }

    if (changed[this.state.selectedChannel].indexOf(e.target.dataset.country) == -1) {
      changed[this.state.selectedChannel].push(e.target.dataset.country);
    }

    state = { prices: prices, changed: changed };

    this.setState(state)
  }

  isValidDecimal = (d) => {
    return 1 * d == d;
  }

  allValidPrices = () => {
    var ret = true;
    console.log("this.state.prices", this.state.prices);

    Object
      .entries(this.state.prices)
      .forEach(([key, valueObject]) => {
        valueObject.forEach(v => {
          if (!this.isValidDecimal(v.bestprice)) {
            ret = - false;
          }
        });
      });

    return ret;
  }

  onChangeAutocomplete = (event, { newValue }) => {
    this.setState({
      editorPriceTypeV: newValue
    });
  }

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  }

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  }

  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    let ret = inputLength === 0 ? [] : this.state.editorPriceType.filter(text =>
      //text.toLowerCase().slice(0, inputLength) === inputValue
      text.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
    );

    return ret;
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  getSuggestionValue = (suggestion) => {
    this.setState({ selectedChannel: suggestion });
    return suggestion;
  }

  // Use your imagination to render suggestions.
  renderSuggestion = (suggestion) => {
    let ret = (
      <div key={"suggestion_" + suggestion}>{suggestion}</div>
    );

    return ret;
  }

  onChannelChange = (v) => {
    this.setState({ selectedChannel: v.value });
    this.props.doSomething(null, { ...this.props.line, domain: v.value }, "do-prices-simulation")
  }

  render() {
    var ret = null;

    if (this.props.line) {

      var { online_ebay_prices, online_platforms_prices } = this.props;
      online_platforms_prices = online_platforms_prices || [];

      var { selectedChannel } = this.state;

      var r = this.props.line;

      var confirmSave = null;
      var helpText = null;
      if (this.state.fixedprice > 0) {
        var fixedpriceNoVat = this.state.fixedprice / 1.22;
        if (this.state.error) {
          confirmSave = (
            <div className="form-group has-error">
              <div>
                <div className="checkbox">
                  <label>
                    <input type="checkbox"
                      name="confirmSave"
                      checked={this.state.confirmSave}
                      onChange={(e) => this.handleInput(e)}
                    /> Confirm wrong fixedprice
                  </label>
                </div>
              </div>
            </div>
          );
        } else {
          confirmSave = (
            <input type="checkbox" style={{ display: "none" }}
              name="confirmSave"
              checked={this.state.confirmSave}
              onChange={(e) => this.handleInput(e)}
            />
          );
        }
        helpText = <p className="help-block">(Margin {(fixedpriceNoVat - r.buyingprice).toFixed(2)}€)</p>;
      } else {
        helpText = <p className="help-block" style={{ display: "none" }}>(Margin {(fixedpriceNoVat - r.buyingprice).toFixed(2)}€)</p>;
      }

      var channel_artiklenummer = null;
      if (selectedChannel !== "") {
        switch (selectedChannel) {
          case "ebay":
            if (this.props.line.online_ebay_artiklenummer) {
              channel_artiklenummer = <span>sku in edock/ebay feed: <span className="badge badge-secondary">{this.props.line.online_ebay_artiklenummer}</span></span>;
            }
            break;
          default:
            if (this.props.line.online_platforms_artiklenummer) {
              channel_artiklenummer = <span>id in platforms feed <span className="badge badge-secondary">{this.props.line.online_platforms_artiklenummer}</span></span>;
            }
            break;
        }
      }

      var channel_prices = [];
      if (selectedChannel !== "" && this.state.prices[selectedChannel]) {
        this.state.prices[selectedChannel].forEach((price) => {
          var style = {};
          switch (selectedChannel) {
            case "ebay":
              if (online_ebay_prices && online_ebay_prices.some(op => op.country == price.country && op.DistributorId == r.DistributorId)) {
                style = { ...style, ...{ borderWidth: 2, borderColor: "darkgreen" } };
              }
              break;
            default:
              if (online_platforms_prices && online_platforms_prices.some(op => op.country == price.country && op.DistributorId == r.DistributorId)) {
                style = { ...style, ...{ borderWidth: 2, borderColor: "darkgreen" } };
              }
              break;
          }

          let selectedFixedChannel = null;
          if (this.state.selectedChannel) {
            selectedFixedChannel = this.state.prices_fixed.filter(c => c.channel == this.state.selectedChannel)[0];
          }

          let isFixed = this.state.selectedChannel && selectedFixedChannel != null &&
            selectedFixedChannel.prices && selectedFixedChannel.prices.filter(p => p.country.toLowerCase() == price.country.toLowerCase()).length > 0;

          channel_prices.push(
            <div key={price.country} className="box-country-price" style={{ display: 'inline-block', width: '140px' }}>
              <label style={{ display: 'block', fontWeight: 'bolder' }}>{Countries.get(price.country)}</label>
              <div className={"input-group " + (!isFixed ? "not-fixed-price-box-opacity" : "")}>
                <div className="input-group-prepend">
                  <span className="input-group-text" style={{ ...style, borderRightWidth: 1 }}>€</span>
                </div>
                <input
                  className={"form-control " + (this.isValidDecimal(price.bestprice) ? "" : " is-invalid")}
                  data-country={price.country}
                  step="0.01" min="0" lang="en"
                  value={formatPrice(price.bestprice)}
                  onChange={this.handlePriceInput}
                  style={{ display: 'inline-block', width: '80px', ...style }}
                /> AAA
              </div>
              {isFixed ? <small id="emailHelp" className="form-text text-muted fixed-price-box-confirmed"><i className="fas fa-check" style={{ color: "green" }}></i> Fixed</small>
                : <small id="emailHelp" className="form-text text-muted">Standard</small>}

              <small style={{ display: 'block' }}>Buying {price.buyingprice} €</small>
            </div>
          );
        })
      }

      ret = (
        <tr>
          <td colSpan={10} style={{ padding: 10 }}>
            <div>
              <ChannelSelector
                pricesExploded={r.pricesExploded}
                onChannelChange={this.onChannelChange}
              />
            </div>
            <PriceSimulation
              line={r}
              />
            <div style={{ paddingTop: 10 }}>
              <fieldset>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group" style={{ display: "inline-block", marginRight: 10 }}>
                      <label>Bestprice</label>

                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text">€</span>
                        </div>
                        <input type="text" className="form-control"
                          name="bestprice"
                          value={formatPrice(r.bestprice)}
                          readOnly={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className={"form-group " + (this.state.error ? " has-error" : "")} style={{ display: "inline-block", marginRight: 10 }}>
                      <label>Fixed price</label>

                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text">€</span>
                        </div>
                        <input type="number" className="form-control" step="0.01"
                          name="fixedprice"
                          value={formatPrice(this.state.fixedprice)}
                          onChange={(e) => this.handleInput(e)}
                        />
                      </div>

                      {helpText}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group" style={{ display: "inline-block", marginRight: 10 }}>
                      <label>Price to show</label>

                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text">€</span>
                        </div>
                        <input type="number" className="form-control" step="0.01"
                          name="pricetoshow"
                          value={formatPrice(this.state.pricetoshow)}
                          onChange={(e) => this.handleInput(e)}
                        />
                      </div>

                    </div>
                  </div>
                </div>
              </fieldset>
              <br />
              {this.state.editorPriceType.length > 0 && (
                <fieldset>
                  <div className="auto-complete-country">
                    <i className="fas fa-tv"></i>
                    <Autosuggest
                      suggestions={this.state.suggestions}
                      onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                      onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                      getSuggestionValue={this.getSuggestionValue}
                      renderSuggestion={this.renderSuggestion}
                      inputProps={{
                        value: formatPrice(this.state.editorPriceTypeV),
                        onChange: this.onChangeAutocomplete,
                        placeholder: 'Seleziona un canale...'
                      }}
                    />
                  </div>
                </fieldset>
              )}
              <br />
              {channel_artiklenummer}
              <fieldset style={{ padding: '5px' }}>
                {channel_prices}
              </fieldset>

              <fieldset>
                <div className="form-group" style={{ display: "block", marginRight: 10 }}>
                  <div style={{ display: 'inline-block' }}>
                    <button className={"btn btn-primary btn-primary-glossy " + (!this.state.confirmSave ? " disabled" : "")}
                      onClick={(e, t) => {
                        let state = JSON.parse(JSON.stringify(this.state));
                        state.prices = {};

                        for (var channel in state.changed) {
                          var channelCountries = this.state.changed[channel];
                          let prices = channelCountries.map(country => {
                            var filteredPrices = this.state.prices[channel].filter(p => p.country == country);
                            return filteredPrices.length > 0 ? filteredPrices[0] : null;
                          });
                          prices = prices.filter(p => p != null);
                          state.prices[channel] = prices;
                        }

                        state.prices = JSON.stringify(state.prices);
                        this.props.doSomething(e, { ...this.props.line, prices: { ...state } }, "do-prices")
                      }}
                      disabled={!this.state.confirmSave || !this.allValidPrices()}
                    >Save</button>
                    {confirmSave}
                  </div>

                  {this.state.hasFixed && (<div style={{ display: 'inline-block', marginLeft: '30px' }}>
                    <button className={"btn btn-danger btn-danger-glossy " + (!this.state.confirmSave ? " disabled" : "")}
                      onClick={(e, t) => {
                        let state = JSON.parse(JSON.stringify(this.state));
                        state.prices = JSON.stringify(state.prices);
                        state.resetFixedPrices = true;
                        this.props.doSomething(e, { ...this.props.line, prices: { ...state } }, "do-prices")
                      }}
                    >Reset fixed prices</button>
                  </div>)}
                </div>
              </fieldset>
            </div>
          </td>
        </tr>
      );
    }

    return ret;
  }
}

export { SingleCaiPrices };

