import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';

import Autocomplete from 'react-autocomplete';
import HasQuantityFlag from "./qty-flag.jsx";
import HasFixedPrice from "./fixed-flag.jsx";

import TireSearchBox from './tire-searchbox.jsx';
import { TireSearchBoxSmarter } from './tire-searchbox-smarter.jsx';

function Dashboard({ dashboard, searchBox, updateChipsSearchBox }) {
  var ret = null;

  const handleSpecialSearch = (e, chip) => {
    const chips = { ...searchBox.chips, [chip]: chip };
    updateChipsSearchBox(chips);
  }

  var btns = []
  if (dashboard && dashboard.ebay && dashboard.ebay) {
    btns.push(<button
      key={"dashboard.ebay.errors"}
      type="button"
      className={"btn " + (dashboard.ebay.errors ? " btn-danger" : " btn-secondary")}
      onClick={e => handleSpecialSearch(e, "edock-errors")}
    >edock errors <span className="badge badge-light">{dashboard.ebay.errors}</span></button>)
  }
  if (dashboard && dashboard.dotDiffers >= 0) {
    btns.push(<button
      key={"dashboard.dotDiffers.errors"}
      type="button"
      className={"btn " + (dashboard.dotDiffers ? " btn-danger" : " btn-secondary")}
      onClick={e => handleSpecialSearch(e, "dot-differs")}
    >DOT errors <span className="badge badge-light">{dashboard.dotDiffers}</span></button>)
  }
  if (dashboard && dashboard.eanDiffers >= 0) {
    btns.push(<button
      key={"dashboard.eanDiffers.errors"}
      type="button"
      className={"btn " + (dashboard.eanDiffers ? " btn-danger" : " btn-secondary")}
      onClick={e => handleSpecialSearch(e, "ean-differs")}
    >EAN errors <span className="badge badge-light">{dashboard.eanDiffers}</span></button>)
  }
  if (dashboard && dashboard.t24Missing >= 0) {
    btns.push(<button
      key={"dashboard.t24Missing.errors"}
      type="button"
      className={"btn " + (dashboard.t24Missing ? " btn-danger" : " btn-secondary")}
      onClick={e => handleSpecialSearch(e, "t24-missing")}
    >T24 missing <span className="badge badge-light">{dashboard.t24Missing}</span></button>)
  }
  ret = <div className="btn-group btn-group-sm mt-2 mb-2" role="group" aria-label="Basic example">
    {btns}
  </div>

  return ret;
}

function AllCaiFilter({ isProcessing, dashboard, searchBox, updateSearchBox, updateChipsSearchBox, refreshNow, specialSearch, newSomething, domain, country }) {
  var ret = null;

  //<div className="dropdown" style={{ paddingTop: 5, paddingBottom: 5 }}>
  //  <button type="button" className="btn btn-default btn-primary btn-sm" data-toggle="dropdown">
  //    <i className="fa fa-plus" />
  //  </button>
  //  <ul className="dropdown-menu dropdown-menu-right">
  //    <li><a href="#" onClick={(e) => this.props.newSomething(e, "brand")}>New brand</a></li>
  //    <li><a href="#" className="disabled" onClick={(e) => this.props.newSomething(e, "tire")}>New tire</a></li>
  //  </ul>
  //</div>

  ret = (
    <fieldset disabled={isProcessing}>
      <div className="row">
        <div className="col-8">
          <Dashboard
            dashboard={dashboard}
            searchBox={searchBox}
            updateChipsSearchBox={updateChipsSearchBox}/>
        </div>
        <div className="col-2 text-right">
          <select className="form-control form-control-sm" style={{ marginTop: 5, marginBottom: 5 }}
            name="iDisplayLength"
            value={searchBox.iDisplayLength}
            onChange={(e) => { updateSearchBox({ iDisplayLength: e.target.value }) }}
          >
            {[100, 200, 500].map((o) => {
              return <option key={"iDisplayLength-" + o} value={o}>{o}</option>
            })}
          </select>

        </div>
        <div className="col-1">
          <HasQuantityFlag
            searchBox={searchBox}
            updateSearchBox={updateSearchBox}
            isProcessing={isProcessing}
          />
        </div>
        <div className="col-1">
          <HasFixedPrice
            searchBox={searchBox}
            updateSearchBox={updateSearchBox}
            isProcessing={isProcessing}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">

          <TireSearchBoxSmarter
            updateChipsSearchBox={updateChipsSearchBox}
            updateSearchBox={updateSearchBox}
            searchBox={searchBox}
            refreshNow={refreshNow}

            newSomething={newSomething}

            domain={domain}
            country={country}
          />
        </div>
      </div>
    </fieldset>
  );

  //<TireSearchBox
  //  updateChipsSearchBox={this.props.updateChipsSearchBox}
  //  updateSearchBox={this.props.updateSearchBox}
  //  searchBox={this.props.searchBox}
  //  refreshNow={this.props.refreshNow}
  ///>

  return ret;
}

export { AllCaiFilter };