import React from 'react';

export default class HasFixedPrice extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    var ret = null;

    const { fixedprice_flag } = this.props.searchBox;
    var flag = null;
    if (fixedprice_flag) {
      flag = <i className="fas fa-toggle-on" style={{ color: "green" }}></i>;
    } else {
      flag = <i className="fas fa-toggle-off"></i>;
    }

    if (this.props.isProcessing) {
      ret = (
        <label style={{ position: "relative" }}
        > Fixed {flag}</label>
      );
    } else {
      ret = (
        <label style={{ position: "relative", cursor: "pointer" }}
          onClick={(e) => this.props.updateSearchBox({ fixedprice_flag: !fixedprice_flag })}
        > Fixed {flag}</label>
      );
    }

    return ret;
  }
}
