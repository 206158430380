import React, { useState, useEffect } from 'react';

import { shallowEqual, useDispatch, useSelector } from "react-redux";

import ProgressLoading from '../../../gcomponents/utils/ProgressLoading'
import { doSomething } from '../../../gcomponents/store/actions/actShared.js';

import ReactJson from 'react-json-view'

function ProductDryRunImport(props) {

  const [data, setData] = useState({});

  const isProcessing = useSelector(state => state.isProcessing ? state.isProcessing.mostRecentStatus : null);

  const dispatch = useDispatch();

  var ret = null;
  var content = null;

  useEffect(() => {
    const globalData = (window.globalData !== undefined) ? window.globalData : null;
    if (globalData) {
      setData(globalData)
    }
  }, [])

  const { sourceFileData, mappedline } = data;

  if (sourceFileData) {
    content =
      <div className="mt-2">
      <div className="mb-2">
        <ReactJson
          src={sourceFileData}
          name="sourceFileData"
          collapsed={true} />
      </div>
      <div className="mb-2">
        <ReactJson
          src={mappedline}
          name="mappedline"
          collapsed={true} />
      </div>
      </div>
  } else {
    content = (
      <div>
        Nothing to show here.
      </div>
    );

  }

  ret = <div>
    <ProgressLoading processing={isProcessing} />
    {content}
  </div>

  return ret;
}

export default ProductDryRunImport;

