import React, { useEffect, useRef, useState } from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfo, faClock } from '@fortawesome/free-solid-svg-icons'

export function InfoButton({ isProcessing, doSomething, item }) {
  const btnRef = useRef(null);
  //const [safety, setSafety] = useState(10);

  //useEffect(() => {
  //  window.setTimeout(() => {
  //    btnRef.current.style.animation = "0.75s "
  //      + "jello"
  //      //+ (animation.animation || "heartBeat")
  //      + " ease";
  //    window.setTimeout(() => {
  //      btnRef.current.style.animation = "none";
  //      if (safety > 0) {
  //        setSafety(safety-1);
  //      }
  //    }, 1000);
  //  }, 0);
  //}, [safety]);


  var ret = null;

  if (item.invalid && item.Quantity>0) {
    //<Tippy
    //  placement="left"
    //  content={<div className="pop" > <div className="pop-title">Warning</div><div className="pop-body">This product was recently changed. Catalogues not updated yet.</div></div >}>
    //  <button
    //    ref={btnRef}
    //    className={"btn btn-mini btn-default " + ((isProcessing) ? " disabled" : "")}
    //    style={{ marginLeft: "5px", width: 32 }}
    //    disabled={isProcessing}
    //    onClick={(e) => { doSomething(e, item, "toggle-details") }}>
    //    <span className="fa-layers fa-fw">
    //      <FontAwesomeIcon icon={faInfo} />
    //      <FontAwesomeIcon icon={faClock} color="#FF4136" transform="shrink-2 up-8 right-12" />
    //    </span>
    //  </button>
    //</Tippy >
    return (
      <Tippy
        placement="left"
        content={<div className="pop" > <div className="pop-title">Warning</div><div className="pop-body">This product was recently changed. Catalogues not updated yet.</div></div >}>
        <button
          ref={btnRef}
          className={"btn btn-mini btn-default " + ((isProcessing) ? " disabled" : "")}
          style={{ marginLeft: "5px", width: 32, position: "relative" }}
          disabled={isProcessing}
          onClick={(e) => { doSomething(e, item, "toggle-details") }}>
          <i className="fas fa-info"></i>
          <i className="fas fa-clock" style={{ position: "absolute", top: 0, right: -4, color: "#FF4136", fontSize: "90%" }}></i>
        </button>
      </Tippy >
    );
  } else {
    return (
      <button
        ref={btnRef}
        className={"btn btn-mini btn-default " + ((isProcessing) ? " disabled" : "")}
        style={{ marginLeft: "5px", width: 32 }}
        disabled={isProcessing}
        onClick={(e) => { doSomething(e, item, "toggle-details") }}>
        <i className="fa fa-info"></i>
      </button>
    );
  }


  return ret;
}


