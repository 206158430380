import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import Select from 'react-select'

import { groupBy, uniqBy } from "lodash";

import Autosuggest from 'react-autosuggest';
import { bool } from 'prop-types';

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion => suggestion.text;

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => {
  var ret = null;
  if (suggestion.text && Object.keys(suggestion).length==1) {
    ret = (
      <div>
        <i className="fas fa-quote-left" style={{ fontSize: "65%", color: "#999", verticalAlign: "super", paddingRight: 1 }}></i>
        {suggestion.text}
        <i className="fas fa-quote-right" style={{ fontSize: "65%", color: "#999", verticalAlign: "super", paddingLeft: 1 }}></i>
      </div>
    );
  } else {
    ret = (
      <div>
        {suggestion.text}
      </div>
    );
  }

  return ret;
}

const brandmatch = (b, t) => {
  return t.length >= 3 && (b.description.toLowerCase().includes(t) || b.id == t)
}

const patternmatch = (b, t) => {
  return t.length >= 3 && (b.text.toLowerCase().includes(t) || b.id == t)
}

const seasonmatch = (s, t) => {
  return t.length >= 3 && s.description.toLowerCase().includes(t)
}

export function TireSearchBoxSmarter({ updateSearchBox, refreshNow, searchBox, updateChipsSearchBox, newSomething, domain, country }) {
  const maxSuggestionsSize = 20;

  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [inputElement, setInputElement] = useState(null);

  const database = useSelector(state => state.tires.database)

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const eanVal = urlParams.get('ean');

    if (eanVal) {
      updateSearchBox({ sSearch: eanVal });
      setValue(eanVal);
      refreshNow();
    }
  }, [])

  const onChange = (event, { newValue }) => {
    updateSearchBox({ sSearch: newValue });
    setValue(newValue);
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequested = ({ value }) => {
    let ret = [];

    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    let tokens = inputValue.split(" ").filter(t=>t).map(t=>t.toLowerCase());

    //var sections = [];
    if (tokens.some(Boolean) && tokens.some(t => t.length >= 3)) {
      const { searchbox_suggestions_database, chips, fulltiresizes } = searchBox;

      var chunks = [];
      var idx = 0;
      //var brands = [];
      //var seasons = [];
      //var widths = [];
      //var ratios = [];
      //var diameters = [];
      //var loads = [];
      //var speeds = [];
      var brand_filtered = [];
      var pattern_filtered = [];
      if (tokens.some(Boolean) && database.brandoptions.some(Boolean)) {
        //for (const t of [...tokens]) {
        //  brand_filtered = database
        //    .brandoptions
        //    //.filter(b => brandmatch(b, t))
        //    .filter(b => tokens.every(t => b.description.toLowerCase().includes(t)))
        //    .map(b => ({ text: b.description, brandid: b.id }));
        //  if (brand_filtered.length > 0) {
        //    chunks[idx++] = brand_filtered;
        //    tokens = tokens.filter(tt => !(t==tt));
        //    break;
        //  }
        //}
        brand_filtered = database
          .brandoptions
          .filter(b => tokens.every(t => b.description.toLowerCase().includes(t)))
          .map(b => ({ text: b.description, brandid: b.id }));
        if (brand_filtered.some(Boolean)) {
          ret = brand_filtered;
        }
      }

      if (!ret.some(Boolean) && tokens.some(Boolean) && database.patternoptions.some(Boolean)) {
        pattern_filtered = database
          .patternoptions
          .filter(p => !searchBox.chips.brandid || searchBox.chips.brandid == p.brandid)
          //.filter(p => brand_filtered.some(bf => bf.brandid == p.brandid))
          .filter(p => 
            searchBox.chips.brandid && tokens.every(t => p.description.toLowerCase().includes(t))
            || tokens.every(t => (p.description + " " + p.brand).toLowerCase().includes(t))
        )
          .map(p => ({ text: p.description, pattern: p.slug, brandid: p.brandid }));
        if (pattern_filtered.length > 0) {
          ret = pattern_filtered;
        }
        //if (patternoptions.length) {
        //  for (const t of [...tokens]) {
        //    pattern_filtered = patternoptions
        //      .filter(b => patternmatch(b, t));
        //    if (pattern_filtered.length > 0) {
        //      chunks[idx++] = pattern_filtered;
        //      tokens = tokens.filter(tt => !(t == tt));
        //      break;
        //    }
        //  }
        //}
      }

      if (!ret.some(Boolean) && tokens.some(Boolean) && database.seasonoptions.some(Boolean)) {
        var season_filtered = database.seasonoptions
          .filter(b => tokens.every(t => b.description.toLowerCase().includes(t)))
          .map(b => ({ text: b.description, season: b.id }));
        if (season_filtered.length) {
          ret = season_filtered;
        }
      }

      if (!ret.some(Boolean) && tokens.some(Boolean) && fulltiresizes.some(Boolean)) {
        var sizes = [];

        var filtered = fulltiresizes.filter(b => {
          return tokens.every(t => (t == b.width || t == b.ratio || t == b.diameter)
            || (b.width+b.ratio+b.diameter).toLowerCase().startsWith(tokens.join("")));
          //return tokens.every(t => Object.keys(b).filter(k => ["width", "ratio", "diameter"].some(x => x == k)).some(k => b[k] && b[k].toLowerCase().includes(t)))
          //  || ([b.width, b.ratio, b.diameter].join("").toLowerCase().startsWith(tokens.join("")));
        });
        if (filtered.length) {
          sizes = uniqBy(filtered.map(f => ({ width: f.width, ratio: f.ratio, diameter: f.diameter, text: f.width + "/" + f.ratio + " r" + f.diameter })), "text");
        }

        filtered = fulltiresizes.filter(b => {
          return tokens.every(t => (t == b.width || t == b.ratio || t == b.diameter || t == b.load || t == (b.speed || "").toLowerCase()))
            || tokens.every(t => (t == b.width || t == b.ratio || t == b.diameter || t == (b.speed || "").toLowerCase()))
              || tokens.every(t => (t == b.width || t == b.ratio || t == b.diameter || t == b.load))
          //return tokens.every(t => Object.keys(b).some(k => b[k] && b[k].toLowerCase().includes(t)))
            || ([b.width, b.ratio, b.diameter, b.load, b.speed].join("").toLowerCase().startsWith(tokens.join("")))
            || ([b.width, b.ratio, b.diameter, b.speed].join("").toLowerCase().startsWith(tokens.join("")));
        });
        if (filtered.length) {
          var tmp = filtered
            .map(b => ({
              //text: Object.values(b).filter(v => v).join(" ").trim(),
              text: Object.entries(b).filter(([k, v]) => v).map(([k, v]) => {
                switch (k) {
                  case "width":
                    return v;
                    break;
                  case "ratio":
                    return "/" + v;
                    break;
                  case "diameter":
                    return "r" + v;
                    break;
                  case "load":
                    return " " + v;
                    break;
                  default:
                    return v;
                    break;
                }
              }).join("").trim(),
              ...b
            }));

          sizes = sizes.concat(tmp);

        }

        if (sizes.length) {
          ret = sizes;
          //chunks[idx++] = sizes;
        }
      }
      //if (database.widthoptions) {
      //  var tmp = database.widthoptions.filter(b => tokens.some(t => b.width.toLowerCase().includes(t))).map(b => ({ text: b.width, width: b.width }));
      //  if (tmp.length) {
      //    chunks[idx++] = tmp;
      //  }
      //}
      //if (database.ratiooptions) {
      //  var tmp = database.ratiooptions.filter(b => tokens.some(t => b.ratio.toLowerCase().includes(t))).map(b => ({ text: b.ratio, ratio: b.ratio }));
      //  if (tmp.length) {
      //    chunks[idx++] = tmp;
      //  }
      //}
      //if (database.diameteroptions) {
      //  var tmp = database.diameteroptions.filter(b => tokens.some(t => b.diameter.toLowerCase().includes(t))).map(b => ({ text: b.diameter, diameter: b.diameter }));
      //  if (tmp.length) {
      //    chunks[idx++] = tmp;
      //  }
      //}
      //if (database.loadoptions) {
      //  var tmp = database.loadoptions.filter(b => tokens.some(t => b.symbol.toLowerCase().includes(t))).map(b => ({ text: b.symbol, load: b.symbol }));
      //  if (tmp.length) {
      //    chunks[idx++] = tmp;
      //  }
      //}
      //if (database.speedoptions) {
      //  var tmp = database.speedoptions.filter(b => tokens.some(t => b.symbol.toLowerCase().includes(t))).map(b => ({ text: b.symbol, speed: b.symbol }));
      //  if (tmp.length) {
      //    chunks[idx++] = tmp;
      //  }
      //}
      //tagoptions tag description

      //let safety = maxSuggestionsSize;
      //ret = chunks.reduce((acc, cur) => {
      //  if (!(acc.length > 0)) {
      //    return cur;
      //  }
      //  if (!(safety > 0)) {
      //    return acc;
      //  }
      //  let _ret = [];
      //  cur.forEach(c => {
      //    acc.forEach(a => {
      //      var text = [a.text, c.text].filter(t => t).join(" ").trim();
      //      if (safety-- > 0) {
      //        _ret.push({ ...a, ...c, text: text });
      //      }
      //    });
      //  });

      //  return _ret;
      //}, []);

    }

    ret.unshift({ text: inputValue });

    ret = ret.slice(0, 20);

    setSuggestions(ret);
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onSuggestionSelected = (e, { suggestion, method }) => {
    const selected = suggestion;

    const chips = { ...searchBox.chips, ...selected, text: undefined };
    //const chips = Object.keys(selected)
    //  .reduce((obj, key) => {
    //    var value = selected[key];
    //    switch (key) {
    //      case "text":
    //        key = "sSearch";
    //        break;
    //    }
    //    obj[key] = value;
    //    return obj;
    //  }, {});

    var sSearch = "";
    if (Object.keys(selected).length == 1 && selected.text) {
      sSearch = selected.text;
    }

    updateChipsSearchBox(chips);
    updateSearchBox({ sSearch: sSearch });
    setValue(sSearch);

    //inputElement.focus();
  }

  const backspace = (e) => {
    if (!value || e.type == "click") {
      const { chips } = searchBox;
      var _chips = chips;
      _chips.sort((a, b) =>
        a.sortIndex < b.sortIndex ? -1 : a.sortIndex > b.sortIndex ? 1 : 0
      )
        .splice(-1, 1);
      updateChipsSearchBox(_chips);
      //for some reason when the input field has focus no redraw is triggered even if new props with updated chips are dispatched
      //the line below force a redraw
      updateSearchBox({ sSearch: "" });

      if (e.type == "click") {
        setValue(value)
        //????
        //this.setState({ value: this.state.value });
        //????
      } else {
        setValue("");
      }
    }
  }

  const handleKeyDown = (e) => {
    switch (e.key) {
      case "Backspace":
        backspace(e);
        break;
      case "Enter":
        var isOpen = document.querySelector(".react-autosuggest__suggestions-container.react-autosuggest__suggestions-container--open");
        if (!isOpen) {
          refreshNow(e);
        }
        break;
      default:
        break;
    }
  }

  const handleDomain = (e) => {
    newSomething(e, "domain");
  }

  const handleCountry = (e) => {
    newSomething(e, "country");
  }

  //const storeInputReference = useCallback((autosuggest) => {
  //  if (autosuggest !== null) {
  //    setInputElement(autosuggest.input);
  //  }
  //},[]);

  // Autosuggest will pass through all these props to the input.
  const inputProps = {
    placeholder: 'Type something',
    value: searchBox.sSearch || '',
    onChange: onChange,
    onKeyDown: handleKeyDown
  };

  const optionsDomain = [{ value: "", label: "None" }, { value: "cerchigomme.it", label: "cerchigomme.it" }, { value: "ebay", label: "ebay" }, { value: "gomme-auto.it", label: "gomme-auto.it" }, { value: "gomzon.com", label: "gomzon.com" },
    { value: "ingrossogomme.com", label: "ingrossogomme.com" }, { value: "reifenkumpel.de", label: "reifenkumpel.de" }, { value: "tyre24", label: "tyre24" }];

  const optionsCountry = [{ value: "", label: "None" }, { value: "AT", label: "AT" }, { value: "BE", label: "BE" }, { value: "DE", label: "DE" }, { value: "DK", label: "DK" }, { value: "ES", label: "ES" }, { value: "FR", label: "FR" },
    { value: "IT", label: "IT" }, { value: "LU", label: "LU" }, { value: "NL", label: "NL" }, { value: "PL", label: "PL" }, { value: "SI", label: "SI" }]

  //ref={storeInputReference}
  // Finally, render it!
  return (
    <div>
      <div className="row">
        <div className="col-10">
          <Autosuggest
            
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            onSuggestionSelected={onSuggestionSelected}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
          />
        </div>
        <div className="col-2">
          <button className="btn btn-primary btn-lg" onClick={(e) => refreshNow(e)}>Search</button>
        </div>
      </div>
      <hr style={{ width: "100%", borderWidth: "4px" }}></hr>
      <div className="row" style={{paddingBottom: "15px"}}>
        <div className="col-6">
          Price domain
          <span style={{paddingLeft: "10px"}}>
            <Select 
              value={optionsDomain.find(opt => opt.value === domain)}
              onChange={handleDomain}
                options={optionsDomain}
              >
            </Select>
          </span>
        </div>
        <div className="col-6">
          Price country
          <span style={{ paddingLeft: "10px" }}>
            <Select
              value={optionsCountry.find(opt => opt.value === country)}
              onChange={handleCountry}
              options={optionsCountry}
            >
            </Select>
          </span>
        </div>
      </div>
      <TireSearchBoxChips
        chips={searchBox.chips}
        updateChipsSearchBox={updateChipsSearchBox}
        backspace={backspace}
        searchbox_suggestions_database={searchBox.searchbox_suggestions_database}
      />
    </div>
  );
}

function TireSearchBoxChips({ updateChipsSearchBox, chips, searchbox_suggestions_database, backspace}) {

  //var missingChips = [];
  //var required = [];
  //Object
  //  .entries(searchbox_suggestions_database)
  //  .forEach(([key, valueObject]) => {
  //    if (valueObject.requiresAll && chips.some(c => c.key == key)) {
  //      required = required.concat(valueObject.requiresAll);
  //    }
  //  });
  //required = [...new Set([].concat(...required))];

  //Object
  //  .entries(searchbox_suggestions_database)
  //  .forEach(([key, valueObject]) => {
  //    if (required.some(r => r == key) && !chips.some(c => c.key == key)) {
  //      missingChips.push(
  //        {
  //          key: key,
  //          text: key+"???",
  //          sortIndex:valueObject.sortIndex
  //        }
  //      );
  //    }
  //  });

  var deleteButton = null;
  var backSpaceButton = null;
  var _chips = [];

  if (Object.keys(chips).length) {
    Object.entries(chips)
      .filter(([key, v]) => v)
      .forEach(([key, v]) => {
        _chips.push(
          <a
            key={"chip"+key}
            href="#"
            onClick={(e) => { updateChipsSearchBox({ ...chips, [key]: undefined }) }}
            className={v.includes("???") ? "badge badge-danger" : "badge badge-success"}
            style={{ marginRight: 2 }}
          >{v}</a>
        );

      });
    deleteButton = <a href="#" className="deleteButton" onClick={(e) => updateChipsSearchBox({})}><i className="fas fa-trash"></i></a>
    backSpaceButton = <a href="#" className="backSpaceButton" onClick={(e) => backspace(e)}><i className="fas fa-backspace"></i></a>
  }

  return (
    <div className="TireSearchBoxChips">
      <div className="row">
        <div className="col-8">
          {_chips}
        </div>
        <div className="col-2">
          <div className="text-right">
            {deleteButton}
            {backSpaceButton}
          </div>
        </div>
        <div className="col-2">
        </div>
      </div>
    </div>
  );
}
