import React, { useEffect, useRef, useState } from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional

import { TireEditor } from './../bo-tire-editor.jsx'
import { TireMapper } from './../bo-tire-editor-mapping.js';

import { type } from 'os';

//import {InfoButton} from "./bo-allcai-list-info-btn.jsx";
import { SingleCai } from "./bo-allcai-list-single.jsx"

class AllCai extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    var ret = null;

    var lines = [];
    if (this.props.results && this.props.results[0]) {
      this.props.results.forEach((r, i) => {
        lines.push(
          <SingleCai
            key={"line-" + i}
            line={r}
            tire_being_edited={this.props.tire_being_edited}
            pushToCart={this.props.pushToCart}
            doSomething={this.props.doSomething}
            isProcessing={this.props.isProcessing}
            ssfd={this.props.ssfd}
            domain={this.props.domain}
            country={this.props.country}
          />
        );
      });
    } else {
      lines.push(
        <tbody key={"line-empty"}>
          <tr>
            <td colSpan={12}>
              <div style={{ paddingTop: 40, paddingBottom: 40, textAlign: "center" }}>
                Empty list
            </div>
            </td>
          </tr>
        </tbody>
      );
    }

    ret = (
      <table className="table table-striped table-bbordered table-condensed all-products" style={{fontFamily:"Roboto Condensed"}}>
        <thead>
          <tr>
            <th>
              season
            </th>
            <th>
              size
            </th>
            <th>
              brand/pattern
            </th>
            <th>
              qty
            </th>
            <th>
            </th>
            <th>
              ga-it
              buyingprice
            </th>
            <th>
            </th>
            <th>
              ga-it
              bestprice
            </th>
            <th>
              domain
              buyingprice 
            </th>
            <th>
              domain
              bestprice
            </th>
            <th>
              minWeb
            </th>
            <th>
            </th>
            <th>
            </th>
          </tr>
        </thead>
        {lines}
      </table>
    );

    return ret;
  }
}

class SingleCaiDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  updateDetails = (updated) => {
    var tire_being_edited_MODIFIED = TireMapper.unMap(updated, "line");
    this.props.doSomething(null, tire_being_edited_MODIFIED, "update-tire-being-edited")
  }

  render() {
    var ret = null;

    if (this.props.line) {
      var { tire_being_edited } = this.props;
      const tire_data_for_editor = TireMapper.mapSomething(tire_being_edited, "line");

      var r = this.props.line;
      //var season = null;
      //if (r.CodStagione == "ALL" && r.DatiAggiuntiviDescrizione.includes("3PMSF")) {
      //  season = (<span style={{ whiteSpace: "nowrap" }}><i className="tire-icon-summer"></i><i className="tire-icon-winter"></i><i className="tire-icon-ms"></i></span>);
      //} else if (r.CodStagione == "EST" && r.DatiAggiuntiviDescrizione.includes("M+S")) {
      //  season = (<span style={{ whiteSpace: "nowrap" }}><i className="tire-icon-summer"></i><i className="tire-icon-ms"></i></span>);
      //} else if (r.CodStagione == "EST") {
      //  season = (<span style={{ whiteSpace: "nowrap" }}><i className="tire-icon-summer"></i></span>);
      //} else if (r.CodStagione == "INV") {
      //  season = (<span style={{ whiteSpace: "nowrap" }}><i className="tire-icon-winter"></i></span>);
      //} else if (r.CodStagione == "ALL") {
      //  season = (<span style={{ whiteSpace: "nowrap" }}><i className="tire-icon-summer"></i><i className="tire-icon-ms"></i></span>);
      //}

      ret = (
        <tr>
          <td colSpan={11} style={{ padding: 10 }}>
            <div style={{ paddingTop: 10 }}>
              <TireEditor
                tire={tire_data_for_editor}
                validation={TireMapper.validate(tire_data_for_editor, this.props.ssfd)}
                brands={this.props.ssfd.brandoptions.map((b) => { return { brandid: b.id, brand: b.description }; })}
                patterns={this.props.ssfd.patternoptions.map((p) => { return { brandid: p.brandid, brand: p.brand, raw: p.description }; })}
                tags={this.props.ssfd.tagoptions
                  .map((t) => { return { value: t.value, label: t.value, description: t.description }; })
                  .sort(function (a, b) {
                    if (a.label < b.label) return -1;
                    if (a.label > b.label) return 1;
                    return 0;
                  })}
                disabledFields={{ picture: true }}

                manualFillTire={this.updateDetails}
                doSave={(e, t, extra) => this.props.doSomething(e, tire_being_edited, "do-save", extra)}
                doTrash={(e, t) => this.props.doSomething(e, tire_being_edited, "toggle-skip")}
                doZero={(e, t) => this.props.doSomething(e, tire_being_edited, "zero-quantity")}
                doSearchByEAN={(e, t) => this.props.doSomething(e, t, "get-elect-by-ean")}
                isProcessing={this.props.isProcessing}
              />
            </div>
          </td>
        </tr>
      );
    }

    return ret;
  }
}


export { AllCai, SingleCaiDetails };

