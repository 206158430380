import React from 'react';
import Autocomplete from 'react-autocomplete';
import Select from 'react-select';

import Switch from "react-toggle-switch";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

class TireEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ean_flag: false,
      ean_check: false,
      manual_changes: [] //manual changes collect the field names user have changed from the product detail editor page
                         //keep attention to userModified log array: it contains all the updates log (name, date, etc...) of the product in different times.
                         //manual changes is used only in this renderization component in order to make considerations about the user interactions.
    };

    this.isProcessing = this.isProcessing.bind(this);
  }

  componentDidMount() {
    //console.log("this.props.tire.EAN", this.props.tire.EAN || this.props.tire.ean);
    this.setState({ originalEAN: this.props.tire.EAN || this.props.tire.ean });
  }

  componentDidUpdate(prevProps) {
    if (typeof this.props.tire !== "undefined" && this.props.tire != null && typeof prevProps.tire !== "undefined" && prevProps.tire != null) {
      let tire = { ...this.props.tire };
      let prevTire = { ...prevProps.tire };
      delete tire.userModified;     //remove user updates log
      delete prevTire.userModified; //from product changes check

      if (JSON.stringify(tire) !== JSON.stringify(prevTire)) {
        //reset manual changes flag when a new tire is loaded in the panel
        this.setState({ manual_changes: ['ean'] });
      }
    }
  }

  handlePatternSelect = (val) => {
    var item = this.props.tire;

    item.pattern = val;

    this.props.manualFillTire(item, true);
  }

  handleToggleEAN = () => {
    this.setState({ ean_flag: !this.state.ean_flag });
    if (this.state.ean_flag) {
      this.handleChange({ target: { type: "text", name: "ean", value: this.state.originalEAN } });
    } 
  }

  handleChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    var item = Object.assign(this.props.tire, { [name]: value });

    var autosave = true;
    switch (name) {
      case "brandid":
        //if the user change the brand, we must clear patterns list
        item.patterns = null;
        break;
      case "pattern":
        autosave = false;
        break;
      case "additional":
        if (Array.isArray(value) && value.length > 0) {
          item.additional = value.map((v, a) => { return v.value }).reduce((v, a) => { return v + " " + a });
        } else {
          item.additional = "";
        }
        break;
    }

    //log manual update
    if (typeof item.userModified !== "undefined") {
      const userModifiedFields = (JSON.parse(item.userModified) || []).filter((um) => um.field !== name);
      const userModifiedField = {
        field: name,
        value: value,
        modifiedon: moment().format("YYYY-MM-DD HH:mm:ss")
      };
      userModifiedFields.push(userModifiedField);
      item.userModified = JSON.stringify(userModifiedFields);
    }

    if (this.state.manual_changes.indexOf(name) < 0)
      this.state.manual_changes.push(name);
    this.props.manualFillTire(item, autosave, e);

    if (name == "ean" && value !== "")
      this.setState({ ean_check: value });
  }

  onBlur = (e) => {
    let name = e.target.name;
    let state = this.state;
    switch (name) {
      case "ean":
        if (this.state.ean_check !== false) {
          let value = state.ean_check;
          this.props.doSearchByEAN(e, { ean: value });
          this.setState({ ean_check: false });
        }
        break;
    }
  }

  isProcessing() {
    return this.props.isProcessing === true || this.props.isProcessing && this.props.isProcessing[0] && this.props.isProcessing.every((v) => { return v });
  }

  //when not provided use local function
  doGoogle = (e, item) => {
    var url = "https://www.google.com/search?q=" + item.brand;
    if (item.ean) {
      url += "+" + item.ean;
    } else {
      if (item.description)
        url += "+" + item.description;
      else if (item.pattern)
        url += "+" + item.pattern;
    }
    var win = window.open(url, '_blank');
    win.focus();
  }

  doSave = (e, item) => {
    if (this.props.options && this.props.options.save == "missing") {
      this.props.doSave(e, item);
    } else if (this.state.manual_changes.length == 1 && this.state.manual_changes[0] === 'ean') {//&& item.elect) {
      //if user have changed only the ean with a new elect
      //do all the updates without asking any confirmation to the user
      //this.props.doSave(e, item, { distributorMode: 'ELECT' });
      this.props.doSave(e, item, { distributorMode: 'SELF' });
    } else {
      var { linked_ta } = this.props.tire;
      linked_ta = linked_ta.split(',');

      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='custom-ui'>
              <h4>Save changes</h4>
              {(!item.elect || !item.ean) && (
                <div>
                  { linked_ta.length > 1 && <p>Apply changes to all suppliers? If applied to all suppliers all products with the same EAN or CAI as this one will be updated.</p> }
                  <button className="btn btn-success btn-success-glossy"
                    onClick={() => {
                    this.props.doSave(e, item, { distributorMode: 'SELF' });
                    onClose();
                  }}>Only for this supplier</button>

                  {
                  item.ean === this.state.originalEAN && linked_ta.length > 1 && (
                    <button className="btn btn-primary btn-primary-glossy"
                      onClick={() => {
                        this.props.doSave(e, item, { distributorMode: '%' });
                        onClose();
                      }}>For all suppliers</button>)
                  }

                  <small className="form-text text-muted">
                    This CAI + brand is linked to {linked_ta.length} {linked_ta.length > 1 ? "suppliers" : "supplier"}.
                  </small>  
                </div>
              )}

              {item.elect && item.ean && (
                <div>
                  <p>Changes will be saved to the elect ean master data. All products with that ean will reflect the changes.</p>
                  <button className="btn btn-success btn-success-glossy"
                    onClick={() => {
                      this.props.doSave(e, item, { distributorMode: 'ELECT' });
                      onClose();
                      }}>Apply changes to the elect ean</button>
                </div>
              )}
              
              <button className="btn btn-danger btn-danger-glossy" onClick={onClose}>Annulla operazione</button>
              {item.elect && item.ean && (
                <p>
                  If you intend to save changes to this specific cai/supplierid, first remove the ean, detaching it from the elect ean, and you will be able to save your changes.
                </p>
              )}
            </div>
          );
        }
      });
    }
  }

  render() {
    var ret = null;

    var item = Object.assign(
      { cai: "", EAN: "", brandid: "", width: "", ratio: "", diameter: "", pattern: "", additional: "", picture: "", season: "", speed: "", load: "", isrunflat: false, isdemo: false, EULGAS: "", EULRAI: "", EULNC: 0, EULDB: "", ismoto: false },
      this.props.tire
    );

    let { manual_changes } = this.state;
    //initial state is a state in which user didn't do any updates or only the ean is changed for a new product
    //initial state <--- (manual_changes.length == 0 || (manual_changes.length == 1 && manual_changes[0] === "ean"))
    if (typeof item.parsedTemptative != "undefined" && (manual_changes.length == 0 || (manual_changes.length == 1 && manual_changes[0] === "ean"))) {
      item.additional = item.parsedTemptative.additional;
      item.additionalinfo = item.parsedTemptative.additional;
      item.dot = item.parsedTemptative.DOT;
      item.isdemo = item.parsedTemptative.isdemo;
    }

    var { linked_ta, ebay_errors } = this.props.tire;
    linked_ta = (linked_ta || "").split(',');

    var patterns = item.patterns;
    if (!patterns && this.props.patterns) {
      patterns = this.props.patterns;
    }
    if (patterns && patterns.length) {
      patterns = patterns.filter(p => p.brandid == item.brandid);
    }

    var brands = [];
    brands.push(
      <option key={"brand"} value={""}>{""}</option>
    );
    if (this.props.brands && this.props.brands[0]) {
      this.props.brands.sort(function (a, b) {
        if (a.brand < b.brand) return -1;
        if (a.brand > b.brand) return 1;
        return 0;
      }).forEach((v, i, a) => {
        brands.push(
          <option key={"brand" + i} value={v.brandid}>{v.brand}</option>
        );
      });
    }

    var dots = [];
    dots.push(
      <option key={"brand"} value={""}>{""}</option>
    );
    var now = (new Date()).getFullYear();
    [...Array(25).keys()].map((v) => { return now - v })
      .forEach((v, i, a) => {
        dots.push(
          <option key={"dots" + i} value={v}>{v}</option>
        );
      });

    var log = [];
    var j = 0;
    if (item.log) {
      item.log.forEach((v) => {
        log.push(
          <div key={"log" + j++} className="mini">{v}</div>
        );
      });
    }
    if (this.props.validation.isValid) {
      log.push(
        <div key={"log" + j++} className="mini" style={{ color: "green" }}><i className="fa fa-check-circle" aria-hidden="true"></i> All mandatory fields are filled in</div>
      );
    } else {
      Object.keys(this.props.validation)
        .filter((k) => { return !(k == "isValid" || k == "patternMatchExactly") })
        .filter((k) => { return !(this.props.validation[k]) })
        .forEach((k) => {
          log.push(
            <div key={"log" + j++} className="mini" style={{ color: "red" }}><i className="fa fa-exclamation-triangle" aria-hidden="true"></i> {k} is invalid</div>
          );
        });
    }
    if (!this.props.validation.brandid) {
      <div key={"log" + j++} className="mini" style={{ color: "red" }}><i className="fa fa-exclamation-triangle" aria-hidden="true"></i> brand is unknown</div>
    }

    if (this.props.validation.newEan > 0) { //0 no
                                            //1 new ean applied
                                            //2 new elected ean applied
      log.push(<div key={"log" + j++} className="mini" style={{ color: "orange" }}><i className="fa fa-exclamation-triangle" aria-hidden="true"></i> new{this.props.validation.newEan == 2 && (<strong>&nbsp;elected</strong>)} ean product is applied</div>);
    }

    if (!this.props.validation.patternMatchExactly && item.pattern) {
      log.push(
        <div key={"log" + j++} className="mini"><i className="fa fa-exclamation-triangle" aria-hidden="true" style={{ color: "#d39e00" }}></i> Pattern is unknown, <b>new?</b></div>
      );
    } else if (this.props.validation.patternMatchExactly && item.pattern) {
      log.push(
        <div key={"log" + j++} className="mini" style={{ color: "green" }}><i className="fa fa-check-circle" aria-hidden="true"></i> Pattern is known</div>
      );
    }
    if (this.props.delay && this.props.delay.ms) {
      log.push(
        <div key={"log" + j++} className="mini" style={{ paddingTop: 3 }}><i className="fa fa-clock-o" aria-hidden="true"></i> {this.props.delay.action} in {this.props.delay.ms}ms (<u><b>esc</b></u> to stop)</div>
      );
    }

    //console.log("isProcessing >>>");
    //this.props.isProcessing.forEach((v) => {
    //  console.log(v);
    //});
    //console.log("<<< isProcessing");
    
    //prepare react-select
    var tags = this.props.tags.map((v) => {
      if (item.family_tags) {
        if (item.family_tags.split(" ").some((s) => {
          return s.toLowerCase() == v.value.toLowerCase();
        })) {
          return Object.assign({}, v, { clearableValue: false });
        }
      }
      return v;
    });
    //

    const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));

    //moto
    var euldb_range = range(60, 90, 1);
    var euldb_options = euldb_range.map((v,i) => {
      return (<option key={"euldb-" + i} value={''+v}>{v}</option>);
    });
    euldb_options.unshift(<option key={"euldb-empty"} value={''}>{''}</option>);

    var ean = (
      <input className="form-control input-sm" type="text" id="EAN" name="ean" placeholder="EAN"
        style={{ fontFamily: "Roboto Condensed" }}
        onBlur={this.onBlur}
        value={item.ean}
        onChange={(e) => { this.handleChange(e) }}
        readOnly={!this.state.ean_flag} />
    );
    if (item.isElect) {
       ean = (
        <input className="form-control input-sm" type="text" id="EAN" name="ean" placeholder="EAN"
           style={{ fontFamily: "Roboto Condensed", backgroundColor: "#2ECC40" }}
           onBlur={ this.onBlur }
          value={item.ean}
          onChange={(e) => { this.handleChange(e) }}
          readOnly={!this.state.ean_flag} />
      );
    }

    var isElectButton = null;
    if (item.isElect!==true && item.isvalidean) {
      isElectButton = <td style={{ paddingRight: 10 }}>
        <div className="form-check" style={{ borderBottom: "3px solid red"}}>
          <input
            className="form-check-input"
            type="checkbox"
            name="electThis"
            checked={item.electThis}
            disabled={this.isProcessing()}
            onChange={(e) => { this.handleChange(e) }}
          />
          <label className="form-check-label" style={{ fontWeight: "normal" }}>
            Create as elect
          </label>
        </div>
      </td>;
    }

    var loads = Array.from({ length: (500 - 1) }, (v, k) => k + 1).map(l => {
      return <option key={"load"+l} value={l}>{l}</option>
    });
    //<input className="form-control input-sm" type="text" placeholder="Load" style={{ width: 60 }}
    //  name="load"
    //  value={item.load}
    //  onChange={(e) => { this.handleChange(e) }}
    //  disabled={this.isProcessing()} />

    ret = (
      <div className="row editor-form">
        <div
          className="col-md-8 editor-container"
        >

          <table style={{}}>
            <tbody>
              <tr>
                <td style={{ paddingRight: 10 }}>
                  <label>
                    Cai/Id
                    </label>
                  <input className="form-control input-sm" type="text" id="CAI" name="CAI" placeholder="CAI"
                    value={item.cai}
                    style={{ fontFamily: "Roboto Condensed"}}
                    readOnly />
                </td>
                <td style={{ paddingRight: 10 }}>
                  <label>
                    EAN <Switch onClick={this.handleToggleEAN} on={this.state.ean_flag} />
                    </label>
                  {ean}
                </td>
                <td style={{ paddingRight: 10 }}>
                  <label>
                    supplierid
                    </label>
                  <input className="form-control input-sm" type="text" id="supplierid" name="supplierid" placeholder="supplierid"
                    style={{ fontFamily: "Roboto Condensed" }}
                    value={item.supplierid}
                    readOnly />
                </td>
              </tr>
            </tbody>
          </table>

          <table>
            <tbody>
              <tr>
                <td style={{ paddingRight: 10 }}>
                  <label className="control-label">
                    Season
                    </label>
                  <select className="form-control input-sm" name="season" style={{ minWidth: 100 }}
                    value={item.season}
                    onChange={(e) => { this.handleChange(e) }}
                    disabled={this.isProcessing()}
                    required>
                    <option value={""} disabled={item.season}></option>
                    <option value={"EST"}>Summer</option>
                    <option value={"INV"}>Winter</option>
                    <option value={"ALL"}>All-Season</option>
                  </select>
                </td>
                <td style={{ paddingRight: 10 }} className={(!this.props.validation.brandid) ? "is-invalid" : ""}>
                  <label>
                    Brand
                        </label>
                  <select className="form-control input-sm" style={{ width: "auto" }}
                    name="brandid"
                    value={item.brandid}
                    onChange={(e) => { this.handleChange(e) }}
                    disabled={this.isProcessing()}>
                    {brands}
                  </select>
                </td>
                <td style={{ paddingRight: 10 }} className={(!this.props.validation.width) ? "is-invalid" : ""}>
                  <label>
                    Width
                        </label>
                  <input className="form-control input-sm" type="text" placeholder="Width" style={{ width: "100%" }}
                    name="width"
                    value={item.width}
                    onChange={(e) => { this.handleChange(e) }}
                    disabled={this.isProcessing()} />
                </td>
                <td style={{ paddingRight: 10 }} className={(!this.props.validation.ratio) ? "is-invalid" : ""}>
                  <label>
                    Ratio
                        </label>
                  <input className="form-control input-sm" type="text" placeholder="Ratio" style={{ width: "100%" }}
                    name="ratio"
                    value={item.ratio}
                    onChange={(e) => { this.handleChange(e) }}
                    disabled={this.isProcessing()} />
                </td>
                {1 != 1 ?
                  <td style={{ paddingRight: 10 }}>
                    <label>
                      &#160;
                        </label>
                    <input className="form-control input-sm" type="text" style={{ width: 30 }}
                      defaultValue={"R"}
                      readOnly />
                  </td>
                  : null
                }
                <td style={{ paddingRight: 10 }} className={(!this.props.validation.diameter) ? "is-invalid" : ""}>
                  <label className="control-label">
                    Diam.
                  </label>
                  <input className="form-control input-sm" type="text" placeholder="Diameter" style={{ width: "100%" }}
                    name="diameter"
                    value={item.diameter}
                    onChange={(e) => { this.handleChange(e) }}
                    disabled={this.isProcessing()} />
                </td>
                <td style={{ paddingRight: 10 }} className={(!this.props.validation.load) ? "is-invalid" : ""}>
                  <label className="control-label">
                    Load
                  </label>
                  <select className="form-control input-sm" name="load" style={{ minWidth: 100 }}
                    value={item.load}
                    onChange={(e) => { this.handleChange(e) }}
                    disabled={this.isProcessing()}
                    required>
                    {loads}
                  </select>
                </td>
                <td style={{ paddingRight: 10 }} className={(!this.props.validation.speed) ? "is-invalid" : ""}>
                  <label className="control-label">
                    Speed
                  </label>
                  <input className="form-control input-sm" type="text" placeholder="Speed" style={{ width: 60 }}
                    name="speed"
                    value={item.speed}
                    onChange={(e) => { this.handleChange(e) }}
                    disabled={this.isProcessing()} />
                </td>
              </tr>
            </tbody>
          </table>

          {item.family_season ?
            <table>
              <tbody>
                <tr>
                  <td style={{ paddingRight: 10 }}>
                    <span className="help-block"><i className="fa fa-exclamation-triangle" aria-hidden="true" style={{ color: "orange" }}></i> Change to <span className="label label-default">season</span> field will affect all tires in the database with same brand+pattern.</span>
                  </td>
                </tr>
              </tbody>
            </table>
            : null
          }

          {item.description &&
            <div className="row" style={{ marginTop: 5 }}>
            <div className="col-12">
              <span className="badge badge-secondary mr-2">Supplier description</span>
              <span className="mini">{item.description}</span>
              <span className="mini" style={{ paddingLeft: 20}}>{item.originalPattern}</span>
              </div>
            </div>
          }

          <table>
            <tbody>
              <tr>
                <td style={{ paddingRight: 10, verticalAlign: "top" }}>
                  <label style={{ display: "block" }}>
                    Pattern
                    </label>
                  {(patterns && patterns[0]) ?
                    <Autocomplete
                      inputProps={{
                        className: "form-control tire-editor-pattern input-sm autofocusme" + (this.props.validation.patternMatchExactly ? " is-valid" : "") + (!this.props.validation.pattern ? " is-invalid" : ""),
                        name: "pattern",
                        required: true,
                        autoFocus: !this.props.validation.pattern,
                        disabled: this.isProcessing()
                      }}
                      wrapperProps={{ className: "form-group " }}
                      wrapperStyle={{
                        display: 'inline-block',
                        position: 'relative',
                        width: 230,
                        height: 38,
                        marginBottom: 0
                      }}

                      getItemValue={(i) => i.disegno ? i.disegno : i.pattern ? i.pattern : i.raw ? i.raw : "!!ERROR!!"}
                      items={patterns}
                      shouldItemRender={(i, value) => !value || value.split(' ').map((v) => {
                        return v.trim();
                      }).every((v) => {
                        const doRender = (i.disegno ? i.disegno : i.pattern ? i.pattern : i.raw ? i.raw : "!!ERROR!!").replace(/\s/g, '').toLowerCase().indexOf(v.toLowerCase()) > -1;
                        //console.log("Autocomplete", "doRender", doRender);
                        return doRender;
                      })}
                      renderMenu={(items, value, style) => {
                        return <div className="react-autocomplete-menu" children={items} />
                      }}
                      renderItem={(i, isHighlighted) =>
                        <div key={"!!" + i.brandid + "-" + (i.disegno ? i.disegno : i.pattern ? i.pattern : i.raw ? i.raw : "!!ERROR!!")}
                          style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                          {(i.disegno ? i.disegno : i.pattern ? i.pattern : i.raw ? i.raw : "!!ERROR!!")}
                        </div>
                      }
                      value={item.pattern}
                      onChange={(e) => { this.handleChange(e) }}
                      onSelect={(val) => { this.handlePatternSelect(val) }}
                    />
                    :
                    <input className="form-control input-sm" type="text" placeholder="Pattern" style={{ width: "100%", minWidth: "230px" }}
                      name="pattern"
                      value={item.pattern}
                      onChange={(e) => { this.handleChange(e) }}
                      disabled={this.isProcessing()} />
                  }
                </td>
                <td style={{ paddingRight: 10, verticalAlign: "top", width: "100%" }}>
                  <label>
                    Additional Info.
                        </label>
                  {
                    1 != 1 ?
                      <input className="form-control input-sm" type="text" placeholder="Additional Info." style={{ width: "100%" }}
                        name="additional"
                        value={item.additional}
                        onChange={(e) => { this.handleChange(e) }}
                        disabled={this.isProcessing()} />
                      : null
                  }
                  <Select
                    name="additional"
                    isMulti={true}
                    options={tags}
                    value={(item.additional || "").split(" ").filter(a => a).map(a => { return { value: a, label: a } })}
                    onChange={(v, action) => { this.handleChange({ target: { type: "react-select", name: "additional", value: v } }) }}
                    components={{
                      Option: ({ innerProps, data, isFocused }) => (
                        <div className="custom-option" {...innerProps} style={{ padding: "10px", borderBottom: "1px solid silver", backgroundColor: isFocused ? "#F8F8F8" : "inherit" }}>
                          {data.label}
                          {
                            data.description ?
                              <span style={{ display: "block", fontFamily: "'PT Sans Narrow', sans-serif", fontSize: "14px" }}>({data.description})</span>
                              :
                              <span style={{ display: "block", fontFamily: "'PT Sans Narrow', sans-serif", fontSize: "14px" }}>( ... ?)</span>
                          }
                        </div>
                      )
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          {
            //clearable={false}
            //components={{
            //  Option: ({ children, innerProps }) => (
            //    <div className="custom-option" ref={innerRef} {...innerProps}>
            //      {children}
            //    </div>
            //    //<div
            //    //  key={option.value}
            //    //>
            //    //  {option.label}
            //    //  {
            //    //    option.description ?
            //    //      <span style={{ display: "block", fontFamily: "'PT Sans Narrow', sans-serif", fontSize: "14px" }}>({option.description})</span>
            //    //      :
            //    //      <span style={{ display: "block", fontFamily: "'PT Sans Narrow', sans-serif", fontSize: "14px" }}>( ... ?)</span>
            //    //  }
            //    //</div>
            //  )
            //}}
          }
          {tags.some((t) => { return t.clearableValue === false; }) ?
            <table>
              <tbody>
                <tr>
                  <td style={{ paddingRight: 10 }}>
                    <span className="help-block"><i className="fa fa-exclamation-triangle" aria-hidden="true" style={{ color: "orange" }}></i> Change to {tags.filter((t) => { return t.clearableValue === false; }).map((t, i) => { return (<span key={"clearableValue" + i}><span className="label label-default">{t.value}</span> </span>); })} not possible here, <strong>edit the pattern instead</strong>.</span>
                  </td>
                </tr>
              </tbody>
            </table>
            : null
          }
          
          <table>
            <tbody>
              <tr>
                <td style={{ paddingRight: 10 }}>
                  <label className="control-label">
                    DOT
                    </label>
                  <select className="form-control input-sm" style={{ width: "auto" }}
                    name="dot"
                    value={item.dot || ""}
                    onChange={(e) => { this.handleChange(e) }}
                    disabled={this.isProcessing()}>
                    {dots}
                  </select>
                </td>
                <td style={{ paddingRight: 10 }}>
                  <label className="control-label">
                    RunFlat
                    </label>
                  <select className="form-control input-sm" name="isrunflat"
                    value={item.isrunflat}
                    onChange={(e) => { this.handleChange(e) }}
                    disabled={this.isProcessing()}
                    required>
                    <option value={false}>No</option>
                    <option value={true}>Yes</option>
                  </select>
                </td>
                <td style={{ paddingRight: 10 }}>
                  <label className="control-label">
                    EULGAS
                    </label>
                  <select className="form-control input-sm" name="EULGAS"
                    value={item.EULGAS}
                    onChange={(e) => { this.handleChange(e) }}
                    disabled={this.isProcessing()}>
                    <option value={''}></option>
                    <option value={'A'}>A</option>
                    <option value={'B'}>B</option>
                    <option value={'C'}>C</option>
                    <option value={'D'}>D</option>
                    <option value={'E'}>E</option>
                    <option value={'F'}>F</option>
                    <option value={'G'}>G</option>
                  </select>
                </td>
                <td style={{ paddingRight: 10 }}>
                  <label className="control-label">
                    EULRAI
                    </label>
                  <select className="form-control input-sm" name="EULRAI"
                    value={item.EULRAI}
                    onChange={(e) => { this.handleChange(e) }}
                    disabled={this.isProcessing()}>
                    <option value={''}></option>
                    <option value={'A'}>A</option>
                    <option value={'B'}>B</option>
                    <option value={'C'}>C</option>
                    <option value={'D'}>D</option>
                    <option value={'E'}>E</option>
                    <option value={'F'}>F</option>
                    <option value={'G'}>G</option>
                  </select>
                </td>
                <td style={{ paddingRight: 10 }}>
                  <label className="control-label">
                    EULNC
                    </label>
                  <select className="form-control input-sm" name="EULNC"
                    value={item.EULNC}
                    onChange={(e) => { this.handleChange(e) }}
                    disabled={this.isProcessing()}>
                    <option value={''}></option>
                    <option value={'1'}>1</option>
                    <option value={'2'}>2</option>
                    <option value={'3'}>3</option>
                  </select>
                </td>
                <td style={{ paddingRight: 10, maxWidth: 80 }}>
                  <label className="control-label">
                    EULDB
                    </label>
                  <select className="form-control input-sm" name="EULDB"
                    value={item.EULDB}
                    onChange={(e) => { this.handleChange(e) }}
                    disabled={this.isProcessing()}>
                    {euldb_options}
                  </select>
                </td>
                <td style={{ paddingRight: 10, verticalAlign: "top" }}>
                  <label>
                    Picture
                        </label>
                  <input className={"form-control input-sm " + (this.isProcessing() || (this.props.disabledFields && this.props.disabledFields["picture"]) ? " disabled" : "")} type="text" placeholder="Picture" style={{ width: "100%" }}
                    name="picture"
                    value={item.picture}
                    onChange={(e) => { this.handleChange(e) }}
                    disabled={this.isProcessing() || (this.props.disabledFields && this.props.disabledFields["picture"])} />
                </td>
              </tr>
            </tbody>
          </table>

          <div className="row" style={{ paddingBottom: 10, paddingTop: 10 }}>
            <div className="col">
              <div className="form-check mb-0 mr-sm-2" style={{display: "inline-block"}}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="ismoto"
                  checked={item.ismoto}
                  disabled={this.isProcessing()}
                  onChange={(e) => { this.handleChange(e) }}
                />
                <label className="form-check-label" style={{ fontWeight: "normal" }}>
                  IsMoto
                </label>
              </div>
              <div className="form-check mb-0 mr-sm-2" style={{ display: "inline-block" }}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="istruck"
                  checked={item.istruck}
                  disabled={this.isProcessing()}
                  onChange={(e) => { this.handleChange(e) }}
                />
                <label className="form-check-label" style={{ fontWeight: "normal" }}>
                  IsTruck
                </label>
              </div>
              <div className="form-check mb-0 mr-sm-2" style={{ display: "inline-block" }}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="isoffroad"
                  checked={item.isoffroad}
                  disabled={this.isProcessing()}
                  onChange={(e) => { this.handleChange(e) }}
                />
                <label className="form-check-label" style={{ fontWeight: "normal" }}>
                  IsOffRoad
                </label>
              </div>
              <div className="form-check mb-0 mr-sm-2" style={{ display: "inline-block" }}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="isdemo"
                  checked={item.isdemo}
                  disabled={this.isProcessing()}
                  onChange={(e) => { this.handleChange(e) }}
                />
                <label className="form-check-label" style={{ fontWeight: "normal" }}>
                  IsDemo
                </label>
              </div>
            </div>
          </div>

          <table>
            <tbody>
              <tr>
                <td style={{ paddingRight: 10 }}>
                  <button className="btn btn-danger btn-danger-glossy"
                    onClick={(e) => this.doSave(e, item)}
                    disabled={!this.props.validation.isValid || this.isProcessing()}>
                    Save
                  </button>
                </td>
                {this.props.doTrash && 
                  <td style={{ paddingRight: 10 }}>
                    <button className="btn btn-outline-secondary" style={{ paddingRight: 10 }}
                      onClick={(e) => { this.props.doTrash(e, item) }}
                      disabled={this.isProcessing()}>
                      <i className={"far " + (item.skip ? "fa-check-square" : "fa-trash-alt")}></i> Trash
                    </button>
                  </td>
                }
                {this.props.doSkip ?
                  <td style={{ paddingRight: 10 }}>
                    <button
                      className="btn btn-outline-secondary" style={{ paddingRight: 10 }}
                      onClick={(e) => { this.props.doSkip(e, item) }}
                      disabled={this.isProcessing()}>
                      Skip
                    </button>
                  </td>
                  : null
                }
                {this.props.doZero ?
                  <td style={{ paddingRight: 10 }}>
                    <button
                      className="btn btn-outline-secondary" style={{ paddingRight: 10 }}
                      onClick={(e) => { this.props.doZero(e, item) }}
                      disabled={this.isProcessing()}>
                      QTY = 0
                    </button>
                  </td>
                  : null
                }
                <td style={{ paddingRight: 10 }}>
                  <button className="btn btn-outline-secondary" style={{ paddingRight: 10 }}
                    onClick={(e) => { this.props.doGoogle ? this.props.doGoogle(e, item) : this.doGoogle(e, item) }}
                    disabled={this.isProcessing()}
                    target="_blank">
                    <i className="fa fa-search" aria-hidden="true"></i> Google
                    </button>
                </td>
                {isElectButton}
                {(item.dot) ?
                  <td style={{ paddingRight: 10 }}>
                    <button className="btn btn-danger disabled" disabled>Warning old DOT!</button>
                  </td>
                  : null
                }
                {(item.json && item.json.Warnings && item.json.Warnings.priceTooLow) ?
                  <td style={{ paddingRight: 10 }}>
                    <button className="btn btn-danger disabled" disabled>Warning price too low!</button>
                  </td>
                  : null
                }
              </tr>
            </tbody>
          </table>

          <SupplierDataInFile item={item} />
        </div>
        <div className="col-md-4">
          <div style={{ padding: "10px 5px", border: "1px solid gray", borderRadius: 3 }}>
            {log}
          </div>
        </div>
      </div>
    );

    //optionRenderer={(option) => {
    //  //console.log(option);
    //  return (
    //    <div
    //      key={option.value}
    //    >
    //      {option.label}
    //      {
    //        option.description ?
    //        <span style={{ display: "block", fontFamily: "'PT Sans Narrow', sans-serif", fontSize: "14px"}}>({option.description})</span>
    //        :
    //        <span style={{ display: "block", fontFamily: "'PT Sans Narrow', sans-serif", fontSize: "14px"}}>( ... ?)</span>
    //      }
    //    </div>
    //  );
    //}}

    return ret;
  }
}

function SupplierDataInFile({ item }) {
  var ret = null;

  const supplierdata = item.supplierdata ? JSON.parse(item.supplierdata) : {};
  if (supplierdata && supplierdata.EAN) {

    var d = [];
    Object.keys(supplierdata).forEach(k => {
      var value = supplierdata[k] + "";
      //if (value === true) {
      //  value = value
      //} else if (value === false) {

      //}
      d.push(
        <span key={item.DistributorId + "#" + item.UniqueTyreId + "#" + k}>
          <span className="badge badge-secondary">{k}</span> <span>{value}</span> </span>
      );
    });

    const sourceFileData = item.sourceFileData ? JSON.parse(item.sourceFileData) : {};
    const sourceFileSupplierData = Object.keys(sourceFileData).map((key) => (
      <span key={"sourceFileData#" + key}>
        <span className="badge badge-secondary">{key}</span>
        <span>{sourceFileData[key]}</span>
      </span>
    ));

    ret = (
      <div>
        <div className="mt-3">
          <h5>Supplier data</h5>
          {d}
        </div>
        {sourceFileSupplierData && (
          <div className="mt-3">
            <h5>Supplier data as in file</h5>
            {sourceFileSupplierData}
          </div>
        )}
        {sourceFileSupplierData && (
          <div className="mt-3">
            <a href={"/bo/products/dry-run-import-map?distributorid=" + item.DistributorId + "&supplierid=" + item.UniqueTyreId} target="_blank">dry run import map</a>
          </div>
        )}
      </div>
    );
  }

  return ret;
}

export { TireEditor };
