import React, { useEffect, useRef, useState } from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional

import { InfoButton } from "./bo-allcai-list-info-btn.jsx";

import { SingleCaiPrices } from "./bo-allcai-list-single-prices.jsx"
import { SingleCaiDetails } from "./bo-allcai-list.jsx"

import { formatPrice } from "../../shared/format.js"

class SingleCai extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    var ret = null;

    if (this.props.line) {
      var r = this.props.line;
      var season = null;
      if (r.CodStagione == "ALL" && r.DatiAggiuntiviDescrizione.includes("3PMSF")) {
        season = (<span style={{ whiteSpace: "nowrap" }}><i className="tire-icon-summer"></i><i className="tire-icon-winter"></i><i className="tire-icon-ms"></i></span>);
      } else if (r.CodStagione == "EST" && r.DatiAggiuntiviDescrizione.includes("M+S")) {
        season = (<span style={{ whiteSpace: "nowrap" }}><i className="tire-icon-summer"></i><i className="tire-icon-ms"></i></span>);
      } else if (r.CodStagione == "EST") {
        season = (<span style={{ whiteSpace: "nowrap" }}><i className="tire-icon-summer"></i></span>);
      } else if (r.CodStagione == "INV") {
        season = (<span style={{ whiteSpace: "nowrap" }}><i className="tire-icon-winter"></i></span>);
      } else if (r.CodStagione == "ALL") {
        season = (<span style={{ whiteSpace: "nowrap" }}><i className="tire-icon-summer"></i><i className="tire-icon-ms"></i></span>);
      }
      var tip = "r";
      if (["Y", "W", "ZR"].some((v) => { return r.CodVelocita && v == r.CodVelocita.toUpperCase() })) {
        tip = "zr";
      }

      var details = null;
      if (r.ui && r.ui.details) {
        details = (
          <SingleCaiDetails
            line={this.props.line}
            tire_being_edited={this.props.tire_being_edited}
            doSomething={this.props.doSomething}
            isProcessing={this.props.isProcessing}
            ssfd={this.props.ssfd}
          />
        );
      }

      var channels = [];
      var online_ebay_prices = [];
      var online_platforms_prices = [];
      if (r.prices) {
        var channels_prices = r.prices;
        //var channels_prices = JSON.parse(r.prices);
        channels_prices.forEach((cp, idx) => {
          if (cp.prices && cp.prices.length) {
            var badge = "badge badge-light ";
            var alt = "";
            var href = null;
            var key = null;
            var channel_name = cp.channel;
            var error = null;
            switch (cp.channel) {
              case "ebay":
                if (r && r.online_ebay_prices) {
                  online_ebay_prices = JSON.parse(r.online_ebay_prices);
                  if (online_ebay_prices && online_ebay_prices.some(ep => ep.DistributorId == r.DistributorId && (ep.UniqueTyreId == r.UniqueTyreId || ep.supplierid == r.UniqueTyreId))) {
                    badge = "badge badge-success ";
                  }
                  if (r.ebay_errors) {
                    error = (<i className="fas fa-exclamation-triangle" style={{ color: "#dc3545", verticalAlign: "middle" }}></i>);
                    badge = "badge badge-danger ";
                  }
                }
                href = "/bo/ebay/" + r.online_ebay_artiklenummer;
                break;
              default:
                channel_name = "platforms";
                online_platforms_prices = JSON.parse(r.online_platforms_prices);
                if (online_platforms_prices && online_platforms_prices.some(ep => ep.DistributorId == r.DistributorId && ep.UniqueTyreId == r.UniqueTyreId)) {
                  badge = "badge badge-success ";
                  alt = online_platforms_prices.filter(ep => ep.DistributorId == r.DistributorId && ep.UniqueTyreId == r.UniqueTyreId).map(ep =>
                    <div key={"platforms" + ep.country}>{ep.country} {ep.estimatedDaysToDelivery}days</div>
                  );//.join('</br>');
                }
                break;
            }

            var channel_entry = null;
            if (alt && r) {
              key = "tippy-channel-" + cp.channel + "-" + r.DistributorId + "-" + r.UniqueTyreId;
              channel_entry = (
                <div
                  key={"tippy-channel-" + cp.channel + "-" + r.DistributorId + "-" + r.UniqueTyreId}
                  style={{ display: "inline" }}>
                  <Tippy
                    placement="left"
                    content={<div className="pop" > <div className="pop-title">countries</div><div className="pop-body">{alt}</div></div >}>
                    <span
                      style={{ display: "block", textAlign: "right" }}
                    >
                      {error}
                      <span
                        className={badge + " all-products-prices-channel-" + cp.channel}
                        style={{ marginTop: idx > 0 ? 2 : 0, display: "inline-block" }}
                      >{channel_name}</span>
                    </span>
                  </Tippy>
                </div>
              );
            } else {
              key = "channel-" + cp.channel + "-" + r.DistributorId + "-" + r.UniqueTyreId;
              channel_entry = (
                <span
                  style={{ display: "block", textAlign: "right" }}
                  key={"channel-" + cp.channel + "-" + r.DistributorId + "-" + r.UniqueTyreId}
                >
                  {error}
                  <span
                    className={badge + " all-products-prices-channel-" + cp.channel}
                    style={{ marginTop: idx > 0 ? 2 : 0, display: "inline-block" }}
                  >{channel_name}</span>
                </span>
              );
            }

            if (href) {
              channel_entry = <a key={key} href={href} target="_blank">
                {channel_entry}
              </a>
            } else {
              channel_entry = <span key={key}>{channel_entry}</span>
            }

            channels.push(
              channel_entry
            );
          }
        });
      }

      var economics = null;
      if (r.ui && r.ui.economics) {
        economics = (
          <SingleCaiPrices
            line={this.props.line}
            online_ebay_prices={online_ebay_prices}
            online_platforms_prices={online_platforms_prices}
            doSomething={this.props.doSomething}
            isProcessing={this.props.isProcessing}
            ssfd={this.props.ssfd}
          />
        );
      }

      var prices = null;
      var { bestprice, untouchedprice, fixedprice, pricetoshow, online_bestprice, rnk } = r;
      bestprice = fixedprice || bestprice;
      untouchedprice = pricetoshow || untouchedprice || bestprice;
      if (bestprice != untouchedprice) {
        if (online_bestprice && rnk == 1) {
          prices = (
            <span>
              <span style={{ fontWeight: 700, marginRight: 10 }}>{formatPrice(bestprice)}</span>
              <div style={{ fontSize: "85%" }}>
                <span className="strikethrough">{untouchedprice}</span>
              </div>
            </span>
          );
        } else {
          prices = (
            <span>
              <span style={{ fontWeight: 700, marginRight: 10 }}>{formatPrice(bestprice)}</span>
              <div style={{ fontSize: "85%" }}>
                <span className="strikethrough">{untouchedprice}</span>
              </div>
            </span>
          );
        }
      } else {
        prices = (
          <span>
            <span style={{ fontWeight: 700 }}>{formatPrice(bestprice)}</span>
          </span>
        );
      }

      var eulabel = null;
      if (r.EULGAS && r.EULRAI && r.EULDB) {
        eulabel = (
          <div >
            <div style={{ display: "inline-block", maxWidth: "12px", marginLeft: 8 }}><img src="https://cdn-images.gomme-auto.it/i/eugas-mini.png" className="img-responsive" /></div> {r.EULGAS}<div style={{ display: "inline-block", maxWidth: "12px", marginLeft: 8 }}><img src="https://cdn-images.gomme-auto.it/i/eurai-mini.png" className="img-responsive" /></div> {r.EULRAI}<div style={{ display: "inline-block", maxWidth: "12px", marginLeft: 8 }}><img src="https://cdn-images.gomme-auto.it/i/eudb-mini.png" className="img-responsive" /></div> {r.EULDB}
          </div>
        );
      }

      var filteredBestprice = null;
      var filteredBuyingprice = null;

      if ((this.props.domain != null) && (this.props.country != null)) {
        var priceExploded = r.pricesExploded.find((e) => (e.country === this.props.country) && (e.domain === this.props.domain));

        if (priceExploded != null) {
          filteredBestprice = (
            <span>
              <span style={{ fontWeight: 700 }}>{formatPrice(priceExploded.bestprice)}</span>
            </span>
          );
          filteredBuyingprice = (
            <div style={{ whiteSpace: "nowrap" }}>
              {formatPrice(priceExploded.buyingprice)}
            </div>
          );
        }
      }

      var MinWebPrice = null;
      if (r.MinWebPrice) {
        if (r.MinWebPrice > r.bestprice) {
          MinWebPrice = (<span>{formatPrice(r.MinWebPrice)} <i className={"fa fa-arrow-up"} style={{ color: "green", opacity: .25 }}></i></span>);
        } else {
          MinWebPrice = (<span>{formatPrice(r.MinWebPrice)} <i className={"fa fa-arrow-down"} style={{ color: "red", opacity: .5 }}></i></span>);
        }
      }
      var elect = null;
      if (r.elect) {
        elect = <i className="fas fa-clipboard-check" style={{ color: "green" }} />
      }

      ret = (
        <tbody style={{ border: (r.ui && (r.ui.details || r.ui.economics)) ? "3px solid red" : "initial" }}>
          <tr >
            <td>
              {season}
            </td>
            <td style={{ fontFamily: "Roboto Condensed" }}>
              <div>
                <span>{r.Larghezza}/{r.Serie}{tip}{r.Diametro} {r.Carico}{r.CodVelocita}</span> <span>{r.DatiAggiuntiviDescrizione}</span> {r.dot.length > 0 && (<span className="label label-warning">{r.dot}</span>)} {r.isdemo && (<span className="label label-warning">DEMO</span>)}
              </div>
              {eulabel} {r.IsRunFlat ? <span className="label label-default">Run Flat</span> : null}
            </td>
            <td style={{ fontFamily: "Roboto Condensed", fontSize: "16px" }}>
              <div style={{ fontWeight: 700 }}>
                {r.brand} {elect}
              </div>
              <div>
                {r.Disegno}
              </div>
            </td>
            <td style={{ textAlign: "right" }}>
              {r.Quantity}
            </td>
            <td>
              <span className="label label-light">{r.twoletterscode}</span>
            </td>
            <td>
              <div style={{ whiteSpace: "nowrap" }}>
                {formatPrice(r.buyingprice)}
                <a className="btn btn-mini btn-default ml-2 p-0" href={"/bo/tires/price-history/" + r.hash} title="Price history" target="_blank">
                  <i className="fas fa-history"></i>
                </a>
              </div>
              <div style={{ fontSize: 12, opacity: .75 }}>fifo {formatPrice(r.FIFOPrice)}</div>
            </td>
            <td>
              <i className="fa fa-circle" aria-hidden="true" style={{ color: (r.skip == 1 ? "red" : "green"), opacity: (r.online_bestprice && r.rnk == 1 ? ".8" : ".25") }}></i>
            </td>
            <td>
              {prices}
            </td>
            <td>
              {filteredBuyingprice}
            </td>
            <td>
              {filteredBestprice}
            </td>
            <td>
              {MinWebPrice}
            </td>
            <td>
              <div style={{ display: "inline-block" }}>
                <button className={"btn btn-mini btn-danger btn-danger-glossy " + ((r.skip == 1 || this.props.isProcessing || !(r.buyingprice > 0 && r.Quantity > 0)) ? " disabled" : "")}
                  disabled={r.skip == 1 || this.props.isProcessing || !(r.buyingprice > 0 && r.Quantity > 0) || r.supplierIsDisabled}
                  onClick={(e) => { this.props.pushToCart(e, r) }}
                >
                  <i className="fa fa-shopping-cart" aria-hidden="true"></i> buy
                </button>
                <InfoButton isProcessing={this.props.isProcessing} doSomething={this.props.doSomething} item={r} />
                <button className={"btn btn-mini btn-default " + ((this.props.isProcessing) ? " disabled" : "")}
                  style={{ marginLeft: "5px", fontWeight: 700 }}
                  disabled={this.props.isProcessing /*|| this.props.line.FIFOPrice === 0 || this.props.Quantity === 0*/}
                  onClick={(e) => { this.props.doSomething(e, r, "toggle-economics") }}>
                  €
                </button>
              </div>
              <div style={{ display: "inline-block", float: "right" }}>
                {channels}
              </div>
            </td>
            <td></td>
          </tr>
          {details}
          {economics}
        </tbody>
      );
    }

    //<button className={"btn btn-mini btn-default " + ((this.props.isProcessing) ? " disabled" : "")}
    //  style={{ marginLeft: "5px" }}
    //  disabled={this.props.isProcessing}
    //  onClick={(e) => { this.props.doSomething(e, r, "toggle-details") }}>
    //  <i className="fa fa-info"></i>
    //</button>

    return ret;
  }
}

export { SingleCai };

