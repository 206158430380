import React, { useState, useEffect } from 'react';

import { shallowEqual, useDispatch, useSelector } from "react-redux";

import ProgressLoading from '../../../gcomponents/utils/ProgressLoading'
import { doSomething } from '../../../gcomponents/store/actions/actShared.js';

import ReactJson from 'react-json-view'

function EbayItem(props) {

  const [data, setData] = useState(undefined);

  const isProcessing = useSelector(state => state.isProcessing ? state.isProcessing.mostRecentStatus : null);

  const dispatch = useDispatch();

  var ret = null;
  var content = null;

  useEffect(() => {
    const globalData = (window.globalData !== undefined) ? window.globalData : null;
    if (globalData) {
      setData(globalData)
    }
  }, [])

  const item = data ? data.item : null;
  const log = data ? data.log : null;
  const lastAttempts = data ? data.lastAttempts : null;
  
  const handleSendToEdock = (e, artiklenummer) => {
    var url = "/bo/ebay/" + artiklenummer + "/send";
    var payload = {};
    return dispatch(doSomething(url, payload, "POST")).then(d => {
      if (d && d.item) {
        setData(d)
      }
      return d
    });
  }

  const handleTouchOnEdock = (e, artiklenummer) => {
    var url = "/bo/ebay/" + artiklenummer + "/touch";
    var payload = {};
    return dispatch(doSomething(url, payload, "POST")).then(d => {
      if (d && d.item) {
        setData(d)
      }
      return d
    });
  }

  if (item) {
    content =
      <div className="mt-2">
        <div className="mb-2">
          <button className="btn tbn-sm btn-primary"
            onClick={e => handleSendToEdock(e, item.artiklenummer)}>
            <span>Send update to edock</span>
            <i className="fas fa-redo-alt ml-2"></i>
          </button>
          <button className="ml-2 btn tbn-sm btn-default"
            onClick={e => handleTouchOnEdock(e, item.artiklenummer)}>
            <span>Check on edock</span>
            <i className="fas fa-eye ml-2"></i>
          </button>
        </div>
        <LastActionLog log={log} />
        <DataFromDB item={item} />
        <LastAttempts lastAttempts={lastAttempts} />
      </div>
  } else {
    content = (
      <div>
        Nothing to show here.
      </div>
    );

  }

  ret = <div>
    <ProgressLoading processing={isProcessing} />
    {content}
  </div>

  return ret;
}

function LastAttempts({ lastAttempts }) {
  var ret = []

  if (lastAttempts) {
    lastAttempts.forEach((l, i) => {
      var bomb = null
      if (l.attempt && l.attempt.exception) {
        bomb = <div className="mb-2"><span className="badge badge-danger"><i className="fas fa-exclamation-triangle"></i> {l.attempt.exception.Message}</span></div>
      }
      ret.push(
        <div key={"lastAttempts-"+i}
          className="card mb-2">
        <div className="card-body">
            <h5 className="card-title"><i className="fas fa-stream"></i> {l.createdon} attempt</h5>
            {bomb}
          <ReactJson
            src={l.attempt}
            collapsed={true} />
        </div>
        </div>
      )
    })
  }

  return ret;
}
function LastActionLog({ log }) {
  var ret = null

  if (log) {
    ret = <div className="card mb-2">
      <div className="card-body">
        <h5 className="card-title"><i className="fas fa-stream"></i> Last action log</h5>
        <ReactJson
          src={log}
          collapsed={true} />
      </div>
    </div>
  }

  return ret;
}

function DataFromDB({ item }) {
  var ret = null

  if (item) {
    ret = <div className="card">
      <div className="card-body">
        <h5 className="card-title"><i className="fas fa-database"></i> Item data from DB</h5>
        <ReactJson
          src={item}
          collapsed={true} />
      </div>
    </div>
  }

  return ret;
}

export default EbayItem;

