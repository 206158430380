import React, { useState, useEffect } from 'react';


function TiresPriceHistory(props) {
  var ret = null;

  var history = null;

  if (window.globalData !== undefined) {
    history = window.globalData;
  }

  if (history) {
    var prices = [];
    history.history.forEach((p, i) => {
      prices.push(
        <tr key={p.DistributorId + "#" + p.UniqueTyreId + "#" + i}>
          <td>{p.DistributorId}</td>
          <td>{p.twoletterscode}</td>
          <td>{p.companyname}</td>
          <td>{p.supplierid}</td>
          <td>{p.ean}</td>
          <td>{p.supplier_ean}</td>
          <td>{p.description}</td>
          <td>{p.bestprice}</td>
          <td>{p.stock}</td>
          <td>{p.createdon.replace("T"," ")}</td>
        </tr>
      );
    })
    ret =
      <div>
        <div className="form-inline">
          <div className="form-check mr-3">
          <input type="checkbox" className="form-check-input" id="isEan" checked={history.isEan} onChange={e => { }}/>
            <label className="form-check-label" for="isEan" >isEan</label>
          </div>
          <div className="form-check mr-3">
          <input type="checkbox" className="form-check-input" id="isEbay" checked={history.isEbay} onChange={e => { } }/>
            <label className="form-check-label" for="isEbay" >isEbay</label>
          </div>
          <div className="form-check mr-3">
          <input type="checkbox" className="form-check-input" id="isPlatform" checked={history.isPlatform} onChange={e => { }}/>
            <label className="form-check-label" for="isPlatform" >isPlatform</label>
          </div>
          <div className="form-check mr-3">
          <input type="checkbox" className="form-check-input" id="isHash" checked={history.isHash} onChange={e => { }}/>
            <label className="form-check-label" for="isHash" >isHash</label>
          </div>
        </div>
      <table className="table table-sm" style={{fontFamily:"Roboto Condensed"}}>
          <tbody>
            <tr>
              <th></th>
              <th></th>
              <th>companyname</th>
              <th>supplierid</th>
              <th>ean</th>
              <th>supplier_ean</th>
              <th>description</th>
              <th>bestprice</th>
              <th>stock</th>
              <th>createdon</th>
            </tr>
            {prices}
          </tbody>
        </table>
      </div>
  } else {
    ret = (
      <div>
        Nothing to show here.
      </div>
    );

  }

  return ret;
}

export default TiresPriceHistory;

