class TireMapper {

  static mapSomething(blob, sourceType) {
    var ret = {};

    switch (sourceType) {
      case "line":
        for (const key in TireMapper.mapToLine) {
          ret[key] = blob[TireMapper.mapToLine[key]];
          ret[key] = (ret[key] === null || ret[key] === undefined) ? '' : ret[key];
        }
        break;
      case "missing":
        for (const key in TireMapper.mapToMissing) {
          //console.log("mapSomething", key);
          if (blob[TireMapper.mapToMissing[key]] || blob[TireMapper.mapToMissing[key]] === "" || blob[TireMapper.mapToMissing[key]] === 0) {
            ret[key] = blob[TireMapper.mapToMissing[key]];
          }
          ret[key] = (ret[key] === null || ret[key] === undefined) ? '' : ret[key];
        }
        break;
    }

    return { ...blob, ...ret };
    //return ret;
  }

  static unMap(blob,toType) {
    var ret = {};

    switch (toType) {
      case "line":
        for (const key in TireMapper.mapToLine) {
          ret[TireMapper.mapToLine[key]] = blob[key];
        }
        break;
      case "missing":
        for (const key in TireMapper.mapToMissing) {
          //console.log("k", key, "->", TireMapper.mapToMissing[key]);
          //console.log("v", blob[key], "->", blob[TireMapper.mapToMissing[key]]);
          //if (blob[key] && !blob[TireMapper.mapToMissing[key]]) {
          //  ret[TireMapper.mapToMissing[key]] = blob[key];
          //}

          //do NOT check for !blob[TireMapper.mapToMissing[key]]
          //we are unmapping so overwriting it's the correct things to do
          //otherwise we should not be unmapping
          if (blob[key] || blob[key] === "" || blob[key] === 0) {
            ret[TireMapper.mapToMissing[key]] = blob[key];
          }
        }
        break;
    }
    //console.log("blob->ret",blob,ret)
    return { ...blob, ...ret };
    //return ret;
  }

  static IsValidEAN(s) {
    if (s) {
      var result = 0;
      for (var counter = s.length - 1; counter >= 0; counter--) {
        result = result + parseInt(s.charAt(counter)) * (1 + (2 * (counter % 2)));
      }
      return 0 == ((10 - (result % 10)) % 10);
    } else {
      return false;
    }
  }

  static validate(item, ssfd) {

    const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));

    //moto
    var widthmotooptions = range(60, 330, 10);
    var loadmotooptions = range(40, 60, 1);
    //offroad
    var widthoffroadoptions = range(24, 42, 1);
    //truck
    var widthtruckoptions = range(330, 400, 5);
    var ratiotruckoptions = range(8.5, 15, 1);
    var diametertruckoptions = range(10.5, 24, 1);
    var loadruckoptions = range(120, 160, 1);

    const required = { pattern: true, brandid: true, width: true, ratio: true, diameter: true, load: true, speed: true, season: true };
    var ret = {};
    var whatis = {
      ismoto: false,
      isoffroad: false,
      istruck: false,
      ispcr: false,
      isvalidean: false
    };

    ret.isvalidean = !item.ean || TireMapper.IsValidEAN(item.ean);

    if (typeof item.newEan !== "undefined" && item.newEan > 0) {
      ret.newEan = item.newEan;
    }

    for (var key in required) {
      //replaced by ret.isMoto
      //var validForMoto = false;
      //replaced by ret.isoffroad
      //var validForOffRoad = false;
      //replaced by ret.istruck
      //var validForTruck = false;
      //replaced by ret.ispcr
      //var validForCar = false;

      //var value = required[key];
      ret[key] = (!required[key] || item[key]) ? true : false;
      if (item[key] && ssfd) {
        switch (key) {
          case "pattern":
            ret.patternMatchExactly = false;
            if (item.patterns) {
              if (item.patterns.some((p) => { return (p.disegno ? p.disegno : p.pattern ? p.pattern : p.raw ? p.raw : "!!ERROR!!").toUpperCase() == item[key].toUpperCase() && p.brandid.toUpperCase() == item.brandid.toUpperCase(); })) {
                ret.patternMatchExactly = true;
              }
            }
            if (ssfd.patternoptions && ssfd.patternoptions.some((p) => { return p.description.toUpperCase() == item[key].toUpperCase() && p.brandid.toUpperCase() == item.brandid.toUpperCase(); })) {
              ret.patternMatchExactly = true;
            }
            //ret[key] = ret.patternMatchExactly;
            break;
          case "brandid":
            if (item[key] == "ZZZ" || !ssfd.brandoptions.some((v) => { return v.id == item[key] })) {
              ret[key] = false;
            }
            break;
          case "width":
            whatis.ismoto = widthmotooptions.some((v) => { return v == 1 * item[key] });
            whatis.isoffroad = widthoffroadoptions.some((v) => { return v == 1 * item[key] });
            whatis.istruck = widthoffroadoptions.some((v) => { return v == 1 * item[key] });
            whatis.istruck = whatis.istruck || widthtruckoptions.some((v) => { return v == 1 * item[key] });

            whatis.ispcr = ssfd && ssfd[key + "options"] && ssfd[key + "options"].some((v) => { return v[key] == item[key] });

            // aggiungiamo qui la misura dei ruotini

            whatis.ispcr = whatis.ispcr || (item[key] == 105);

            if (!whatis.ispcr && !whatis.istruck && !whatis.isoffroad && !whatis.ismoto) {
              ret[key] = false;
            }
            break;
          case "ratio":
            whatis.istruck = ratiotruckoptions.some((v) => { return v == 1 * item[key] });

            whatis.ispcr = ssfd && ssfd[key + "options"] && ssfd[key + "options"].some((v) => { return v[key] == item[key] });

            if (!whatis.ispcr && !whatis.istruck && !whatis.isoffroad && !whatis.ismoto) {
              ret[key] = false;
            }
            break;
          case "diameter":
            whatis.istruck = diametertruckoptions.some((v) => { return v == 1 * item[key] });

            whatis.ispcr = ssfd && ssfd[key + "options"] && ssfd[key + "options"].some((v) => { return v[key] == item[key] });

            if (!whatis.ispcr && !whatis.istruck && !whatis.isoffroad && !whatis.ismoto) {
              ret[key] = false;
            }
            break;
          case "load":
          case "speed":
            if (!item[key].trim().length) {
              ret[key] = false;
            }
            if (ssfd && ssfd[key + "options"] && !ssfd[key + "options"].some((v) => { return v.symbol.toLowerCase() == item[key].toLowerCase() })) {
              ret[key] = false;
            }
            //this is a very special case, tires are complicated
            if (key == "load" && item.load == "0" && item.speed == "ZR") {
              ret[key] = true;
            }
            //even more so
            if (key == "load" && ~(item.load.indexOf("/"))) {
              var loads = item.load.split("/");
              ret[key] = loads.every((l) => {
                return ssfd[key + "options"].some((v) => { return v.symbol.toLowerCase() == l.toLowerCase() });
              });
            }

            //maybe it's a truck?
            if (key == "load" && !ret[key]) {
              var loads = item.load.split("/");
              ret[key] = loads.every((l) => {
                return loadruckoptions.some((v) => { return v == l });
              });
            }

            //maybe it's a moto?
            if (key == "load" && !ret[key]) {
              ret[key] = loadmotooptions.some((v) => { return v == 1 * item[key] });
            }

            break;
        }
      }
    }

    //whatis, filter out any property != true
    whatis = Object.keys(whatis).reduce((a, c) => whatis[c] ? { ...a, [c]: whatis[c] } : { ...a }, {});
    //

    ret.isValid = Object.keys(ret).every((k) => { return ret[k] || k == "patternMatchExactly" })

    ret = { ...ret, ...whatis };

    return ret;
  }

}

TireMapper.mapToMissing = {
  distributorid: "DistributorId",
  ean: "EAN",
  supplierid: "UniqueTyreId",
  additional: "additionalinfo",
  description: "description",
  brand: "Brand",
  brandid: "brandid",
  //following mapping only have the purpose to have them enumerated during mapping, and in case the value is null to be set to default value '' to make react happy (Warning: `value` prop on `input` should not be null.)
  width: "width",
  ratio: "ratio",
  diameter: "diameter",
  load: "load",
  speed: "speed",
  dot: "dot",
  EULDB: "EULDB",
  EULGAS: "EULGAS",
  EULNC: "EULNC",
  EULRAI: "EULRAI",

  skip: "skip",

  linked_ta: "linked_ta",
  ebay_errors: "ebay_errors",

  synonyms: "synonyms",
  patterns: "patterns"


};

TireMapper.mapToLine = {
  distributorid: "DistributorId",
  supplierid: "UniqueTyreId",

  pattern: "Disegno",
  cai: "Cai",
  ean: "EAN",
  brandid: "BrandID",
  width: "Larghezza",
  ratio: "Serie",
  diameter: "Diametro",
  load: "Carico",
  speed: "CodVelocita",
  //description:,
  //patterns: [],
  additional: "DatiAggiuntiviDescrizione",
  //picture:,
  season: "CodStagione",
  dot: "dot",
  //XL: "XL",
  isrunflat: "IsRunFlat",
  EULDB: "EULDB",
  EULGAS: "EULGAS",
  EULNC: "EULNC",
  EULRAI: "EULRAI",

  skip: "skip",

  linked_ta: "linked_ta",
  ebay_errors: "ebay_errors"
  //EULGAS: line.EULGAS,
  //EULRAI: line.EULRAI,
  //EULNC: line.EULNC,
  //EULDB: line.EULDB,
  //ismoto: line.ismoto,

  //"family.category": "family_category",
  //"family.season": "family_season",
  //"family.tags": "family_tags"
};

export { TireMapper };